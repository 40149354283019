import images from '@/const/images';

import Loading from '@/components/Loading';

const PageLoading = () => {
  return (
    <div
      className="fixed w-[375px] h-screen z-[105] flex justify-center items-center left-1/2 -translate-x-1/2 top-0 bg-white bg-top bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${images.pageLoading.bg})` }}
    >
      <div className="absolute bottom-[21vh] items-center text-center z-8 flex flex-col gap-[14px]">
        <div className="w-[205px] h-[72px] bg-[url('@/assets/images/pageLoading/title.png')] bg-contain pt-[45px] mx-auto">
          <span className="pageLoading-text text-[16px] font-changa-one">
            {import.meta.env?.VITE_VERSION}
          </span>
        </div>

        <div className="w-[335px] p-[20px] rounded-[20px] border-2 border-[#fff] [background:linear-gradient(180deg,rgba(187,235,138,0.20)_0%,rgba(255,255,255,0.00)_100%),#FFF] flex gap-[20px] relative">
          <div className="text-left">
            <h2 className="text-[14px] font-changa-one text-[#516B4D]">
              Invite your friends to grab TON
            </h2>
            <p className="text-[10px] font-poppins text-black opacity-50 mb-[8px]">
              TON is waiting for you!
            </p>
            <h3 className="text-[22px] font-changa-one text-[#3E4E3B] flex items-center gap-[10px]">
              20,000+
              <img
                className="w-[20px] h-[20px]"
                width={20}
                height={20}
                src={images.pageLoading.tonIcon}
              />
            </h3>
          </div>
          <div className="text-left">
            <h2 className="text-[14px] font-changa-one text-[#516B4D]">
              Don’t miss your shot at the future!
            </h2>
            <p className="text-[10px] font-poppins text-black opacity-50 mb-[8px]">
              Total financing
            </p>
            <h3 className="text-[22px] font-changa-one text-[#3E4E3B] flex items-center gap-[30px]">
              $ 36M
              <div className="flex gap-[5px]">
                <img
                  className="w-[20px] h-[20px]"
                  width={16}
                  height={16}
                  src={images.pageLoading.binanceIcon}
                />
                <img
                  className="w-[20px] h-[20px]"
                  width={16}
                  height={16}
                  src={images.pageLoading.animocaIcon}
                />
              </div>
            </h3>
          </div>

          <img
            className="w-[70px] h-[70px] absolute right-[10px] top-[25px]"
            src={images.pageLoading.goldIcon}
          />
          <img
            className="w-[50px] h-[50px] absolute right-[5px] top-[45px]"
            src={images.pageLoading.zooIcon}
          />
        </div>
        <div className="text-[20px] text-center text-white font-changa-one flex items-center gap-[5px]">
          Loading
          <Loading />
        </div>
      </div>

      <img
        className="absolute bottom-[38vh] right-0 w-[60px] z-9"
        src={images.pageLoading.coin}
      />

      <img
        className="absolute bottom-0 right-0 w-full z-9"
        src={images.pageLoading.flowers}
      />
    </div>
  );
};

export default PageLoading;
