import { useRouteError } from 'react-router-dom';
import images from '@/const/images';

const PageLoading = ({ errorMsg }: { errorMsg?: string }) => {
  const error = useRouteError();

  return (
    <div
      className="fixed w-[375px] h-screen z-[105] flex justify-center items-center left-1/2 -translate-x-1/2 top-0 bg-white bg-top bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${images.pageLoading.bg})` }}
    >
      <div className="absolute bottom-[30vh] text-center z-8 flex flex-col items-center gap-[14px]">
        <div className="w-[205px] h-[72px] bg-[url('@/assets/images/pageLoading/title.png')] bg-contain pt-[45px] mx-auto mb-[10px]">
          <span className="pageLoading-text text-[16px] font-changa-one">
            {import.meta.env?.VITE_VERSION}
          </span>
        </div>
        <div className="w-[80vw] break-words text-[20px] text-center text-white font-changa-one">
          {errorMsg || error?.toString()}
        </div>
      </div>

      <img
        className="absolute top-[48vh] right-0 w-[60px] z-9"
        src={images.pageLoading.coin}
      />

      <img
        className="absolute bottom-0 right-0 w-full z-9"
        src={images.pageLoading.flowers}
      />
    </div>
  );
};

export default PageLoading;
