import { useMount, useUnmount } from 'ahooks';
import React, { useRef, useState } from 'react';
import clsx from 'clsx';

type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;
const ScaleButton: React.FC<Props> = ({
  children,
  className,
  onClick,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showAnimation, setShowAnimation] = useState(false);

  const animationEnd = () => {
    setShowAnimation(false);
  };

  const handleScale = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e);
    if (containerRef.current) {
      setShowAnimation(true);
    }
  };

  useMount(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('animationend', animationEnd);
      containerRef.current.addEventListener('animationcancel', animationEnd);
    }
  });

  useUnmount(() => {
    if (containerRef.current) {
      containerRef.current.removeEventListener('animationend', animationEnd);
      containerRef.current.removeEventListener('animationcancel', animationEnd);
    }
  });

  return (
    <div
      ref={containerRef}
      className={clsx(
        'relative cursor-pointer',
        className ? className : 'w-fit h-fit',
        showAnimation && 'animate-button-scale'
      )}
      onMouseDown={handleScale}
      {...rest}
    >
      {children}
    </div>
  );
};

export default React.memo(ScaleButton);
