import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const LackZooModal = ({
  lackZooModal,
}: {
  lackZooModal: React.MutableRefObject<ModalRef | undefined>;
}) => {
  const navigate = useNavigate();
  return (
    <Modal ref={lackZooModal} icon={images.task.modalIcon1}>
      <div className="w-[339px] pb-[20px] shrink-0 text-black [background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] font-changa-one text-black leading-[20px]">
          Insufficient zoo
        </h3>
        <p className="font-poppins opacity-30 w-[264px] mx-auto leading-[20px] mt-[12px] text-[14px]">
          There are not enough zoo coins.
        </p>
        <div className="flex w-[299px] mx-auto mt-[12px] gap-[8px] items-center pl-[12px] pr-[16px]">
          <div className="text-white font-changa-one text-[12px] font-normal leading-[14px] capitalize flex w-[24px] h-[24px] flex-col justify-center items-center gap-2.5 shrink-0 [background:#FF5C01] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] pt-[3px] pb-[5px] px-[5px] rounded-full">
            1
          </div>
          <span className="font-changa-one text-[14px] font-normal leading-20">
            Go play slot machines to get zoo
          </span>
        </div>
        <div className="flex w-[299px] mt-[12px] mx-auto gap-[8px] items-center pl-[12px] pr-[16px]">
          <div className="text-white font-changa-one text-[12px] font-normal leading-[14px] capitalize flex w-[24px] h-[24px] flex-col justify-center items-center gap-2.5 shrink-0 [background:#FF5C01] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] pt-[3px] pb-[5px] px-[5px] rounded-full">
            2
          </div>
          <span className="font-changa-one text-left text-[14px] font-normal leading-20">
            Improve shiro to increase the speed of mining zoo
          </span>
        </div>
        <div
          className="flex w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] cursor-pointer rounded-[47px] mx-auto text-white mt-[24px] text-nowrap font-changa-one"
          onClick={() => {
            navigate('/spin');
          }}
        >
          To spin
        </div>
      </div>
    </Modal>
  );
};

export default memo(LackZooModal);
