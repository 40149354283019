import React, { useState } from 'react';
import { MaterialType, mininodeIcons, MininodeType } from '@/utils/mininode';
import ImageWithDefault from '@/components/ImageWithDefault';
import images from '@/const/images';
import clsx from 'clsx';
import { formatCurrency } from '@/utils';

const RewardInfo = ({
  list,
  hasLight,
}: {
  list: MaterialType[];
  hasLight: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    if (list.length <= 12) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight + 2 >= scrollHeight) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  if (list.length < 3)
    return (
      <div className="flex items-center justify-center relative w-full">
        <img
          src={images.draw.light}
          alt=""
          className={clsx(
            'w-[375px] object-contain absolute mix-blend-screen top-[38px] pointer-events-none left-1/2 -translate-x-1/2 -translate-y-1/2',
            hasLight ? 'visible animate-show-scale' : 'invisible'
          )}
        />
        <div className="flex justify-center w-fit gap-[60px]">
          {list.map((item) => (
            <div
              key={item.name}
              className="flex flex-col items-center flex-1 z-10"
            >
              <div className="w-[96px] h-[96px] mb-[8px] flex items-center justify-center">
                <ImageWithDefault
                  src={item.url}
                  alt=""
                  className={clsx(
                    item.type === MininodeType.PARTY
                      ? 'w-[80px] h-[80px] rounded-full'
                      : 'w-[96px] h-[96px]'
                  )}
                />
              </div>
              <span className="text-white text-center font-changa-one text-[16px] font-normal leading-[18px] capitalize">
                {item.name}
                {item.type === MininodeType.ADVANCED ? ' MiniNode' : ''}
              </span>
              <span className="text-[#F75F21] font-changa-one text-[20px] font-normal leading-[20px] mt-[4px] capitalize">
                × {item.amount || 0}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  return (
    <div className="w-[310px] relative max-h-[300px]">
      <div
        onScroll={handleScroll}
        className="grid grid-cols-4 gap-[12px] w-[310px] max-h-[300px] overflow-y-auto"
      >
        {list.map((item) => (
          <div key={item.name} className="flex flex-col items-center">
            <div className="w-[48px] h-[48px] mb-[8px] relative flex items-center justify-center">
              <ImageWithDefault
                src={item.url}
                alt=""
                className={clsx(
                  item.type === MininodeType.PARTY
                    ? 'rounded-full w-[40px] h-[40px]'
                    : 'w-[48px] h-[48px]'
                )}
              />
              {mininodeIcons.slice(3).includes(item.name) && (
                <span className="flex left-[36px] top-[4px] text-white text-center font-changa-one text-[10px] font-normal leading-[10px] tracking-[-0.408px] capitalize justify-center items-center gap-2.5 absolute [background:#00CD00] shadow-[0px_-1.2px_0px_0px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] pt-px pb-[3px] px-[4px] rounded-[6px_6px_6px_0px]">
                  Rare
                </span>
              )}
            </div>
            <span className="text-white text-center font-changa-one text-[12px]  font-normal leading-[14px] capitalize">
              {item.name}
            </span>
            <span className="text-[#F75F21] font-changa-one text-[14px]  font-normal leading-[20px] mt-[4px] capitalize">
              × {formatCurrency(item.amount || 0, true)}
            </span>
          </div>
        ))}
      </div>
      <img
        src={images.draw.catch.down}
        alt=""
        className={clsx(
          'w-[24px] h-[24px] absolute bottom-[-14px] left-1/2 -translate-y-[6px] -translate-x-1/2 animate-float',
          list.length > 12 && isVisible ? 'visible' : 'invisible'
        )}
      />
    </div>
  );
};

export default React.memo(RewardInfo);
