import images from '@/const/images';
import { useNavigate } from 'react-router-dom';

const MoreMiniNode = () => {
  const navigate = useNavigate();
  return (
    <div className="w-[375px] min-h-screen text-black relative pt-[36px] pb-[40px] shrink-0 [background:linear-gradient(180deg,#FFDBBB_0%,#FFF_100%)] flex flex-col items-center">
      <h3 className="text-[20px] leading-[20px] text-center mb-[6px] font-changa-one text-black">
        More Mininode
      </h3>
      <p className="text-[#FD6019] text-center font-changa-one text-[32px] font-normal leading-[32px] capitalize">
        Get more $veTABI
      </p>
      <div className="w-fit flex items-center pl-[12px] gap-[4px] relative h-[30px] shrink-0 border [background:rgba(0,0,0,0.05)] rounded-[16px] border-solid mt-[10px] border-[#EAC7A6]">
        <img
          src={images.draw.queen}
          alt=""
          className="w-[30px] h-[30px] mt-[-1px]"
        />
        <span className="w-full text-right pr-[24px] font-changa-one text-[14px] font-normal leading-[16px]">
          Higher levels, Get more ZOO
        </span>
        <img
          src={images.header.zooIcon}
          alt=""
          className="w-[26px] h-[26px] absolute right-[-13px] bottom-[-8px]"
        />
      </div>
      <img
        src={images.more.moreStarCoin}
        alt=""
        className="w-[24px] h-[24px] absolute left-[16px] top-[133px]"
      />
      <div className="w-[295px] h-[400px] px-[20px] pb-[24px] flex flex-col items-center shrink-0 mt-[16px] [background:#FFF] shadow-[0px_-3px_0px_0px_#DCDCDC_inset,0px_1px_4px_0px_rgba(0,0,0,0.40)] rounded-[16px]">
        <div className="flex flex-col w-full items-center relative pb-[4px] border-b border-solid border-b-[rgba(0,0,0,0.10)]">
          <img
            src={images.more.moreBall}
            alt=""
            className="w-[148.042px] h-[169.713px]"
          />
          <span className="text-black text-center font-poppins w-full text-[12px] absolute bottom-[16px] font-normal leading-[14px] opacity-30">
            Draw to get mininode , star and earn more
          </span>
        </div>
        <div className="w-full flex flex-col gap-[12px] pt-[6px] items-center text-center font-changa-one font-normal">
          <img
            src={images.more.moreJerry}
            alt=""
            className="w-[223px] h-[144px]"
          />
          <div className='flex flex-col'>
            <span className="leading-[20px] text-[18px]">
              More high-quality mininode
            </span>
            <span className="leading-[20px] text-[18px] text-[#FD6019]">
              More $veTABI
            </span>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          navigate('/index');
        }}
        className="flex cursor-pointer w-[291px] h-[46px] mt-[20px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white text-nowrap font-bold font-changa-one"
      >
        Go
      </div>
    </div>
  );
};

export default MoreMiniNode;
