const keyframe = [
  { transform: 'rotate(0deg) translateY(-50%)', offset: 0 },
  { transform: 'rotate(-20deg) translateY(-50%)', offset: 0.1 },
  { transform: 'rotate(20deg) translateY(-50%)', offset: 0.2 },
  { transform: 'rotate(-20deg) translateY(-50%)', offset: 0.3 },
  { transform: 'rotate(20deg) translateY(-50%)', offset: 0.4 },
  { transform: 'rotate(0deg) translateY(-50%)', offset: 0.45 },
  { transform: 'rotate(0deg) translateY(-25%)', offset: 0.55 },
  { transform: 'rotate(0deg) translateY(-10%)', offset: 0.65 },
  { transform: 'rotate(0deg) translateY(0)', offset: 0.75 },
  { transform: 'rotate(0deg) translateY(-10%)', offset: 0.85 },
  { transform: 'rotate(0deg) translateY(-25%)', offset: 0.9 },
  { transform: 'rotate(0deg) translateY(-35%)', offset: 0.95 },
  { transform: 'rotate(0deg) translateY(-50%)', offset: 1 }
]
export default class AnimationFrame {
  public animation: Animation | null = null;

  constructor(id: string) {
    const element = document.getElementById(id);
    if (!element) return;

    const keyframes = new KeyframeEffect(
      element,
      keyframe,
      {
        duration: 2000,
        easing: 'ease-out',
        fill: 'forwards',
        composite: 'replace'
      }
    );

    this.animation = new Animation(keyframes);
  }

  getCurrentOffset(): number | null {
    if (!this.animation || !this.animation.effect) {
      return null;
    }

    const timing = this.animation.effect.getComputedTiming();
    const currentTime = this.animation.currentTime as number | null;

    if (currentTime === null) {
      return null;
    }

    return currentTime / Number(timing.duration);
  }
}
