import { getDrawConfig } from '@/api/draw';
import { IRouterRootLoaderData } from '@/router';
import { MaterialType, MininodeType } from '@/utils/mininode';
import { useMount, useRequest } from 'ahooks';
import { useCallback, useMemo } from 'react';
import { useAsyncValue } from 'react-router-dom';


const useDrawConfig = () => {
  const loaderData = useAsyncValue() as IRouterRootLoaderData;

  const { data, run } = useRequest(getDrawConfig, {
    manual: true,
    cacheKey: 'drawConfig',
  });

  useMount(() => {
    if (!loaderData?.drawConfig) {
      run();
    }
  });

  const values = useMemo(() => {
    if (loaderData && loaderData.drawConfig) return loaderData?.drawConfig;

    return data;
  }, [loaderData?.drawConfig, data]);

  const getCurrentFreeDraw = useCallback((count = 1) => {
    let currentZoo = 0;
    if (values && values.free_draw_times_config) {
      currentZoo = values?.free_draw_times_config?.find(
        (item) => item.attempt_number === count
      )?.zoo_required || 0;
    }
    return currentZoo;
  }, [values]);

  const tokens = useMemo(() => {
    if (values && values.party_tokens_list) {
      return values.party_tokens_list.map(item => ({ name: item.token_name, url: item.icon_url, type: MininodeType.PARTY, partyIcon: item.party_icon_url }) as MaterialType);
    }
    return [];
  }, [values]);

  return {
    getCurrentFreeDraw,
    tokens,
  };
};

export default useDrawConfig;
