const tabiZooUserInviteCodeKey = 'tabi-zoo-user-invite-code';

export const getInviteCode = () => {
  return localStorage.getItem(tabiZooUserInviteCodeKey);
};

export const setInviteCode = (token: string) => {
  localStorage.setItem(tabiZooUserInviteCodeKey, token);
};

export const clearInviteCode = () => {
  localStorage.removeItem(tabiZooUserInviteCodeKey);
};
