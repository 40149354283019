import React, { memo } from 'react';
import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import { useNavigate } from 'react-router-dom';

const LackStarModal = ({
  lackStarModal,
}: {
  lackStarModal: React.MutableRefObject<ModalRef | undefined>;
}) => {
  const navigate = useNavigate();
  return (
    <Modal ref={lackStarModal} icon={images.draw.coinStar}>
      <div className="w-[339px] pb-[20px] shrink-0 text-black [background:linear-gradient(180deg,rgba(255,224,85,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] font-changa-one text-black leading-[20px]">
          Get more Star
        </h3>
        <p className="font-poppins text-center opacity-30 w-[275px] mx-auto leading-[20px] mt-[12px] text-[14px]">
          Your star balance is insufficient. Would you like to recharge to get
          more reward opportunities
        </p>
        <div
          className="flex w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] cursor-pointer rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-changa-one"
          onClick={() => {
            navigate('/star/recharge', { state: location.pathname });
          }}
        >
          Go
        </div>
      </div>
    </Modal>
  );
};

export default memo(LackStarModal);
