/*
 * Created Date: December 10th 2024, 2:32:06 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 10th 2024, 2:32:06 pm
 */
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store from 'store';

export const getFpVisitorId = async () => {
  try {
    if (!store.get('fpHash')) {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      store.set('fpHash', visitorId);
      return visitorId;
    }

    return store.get('fpHash');
  } catch (error) {}
};
