import React, { useEffect, useRef, useMemo } from 'react';
import { getDynamicBallUrls } from '@/utils/mininode';
import clsx from 'clsx';

interface Props {
  startPhysics?: boolean;
}

interface BallConfig {
  url: string;
  className: string;
}

const ANIMATION_DURATION = 900;
const ANIMATION_DELAY = 25;
const ballUrls = getDynamicBallUrls();
const BallContainer: React.FC<Props> = ({ startPhysics }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ballsRef = useRef<NodeListOf<Element> | null>(null);
  const animationsRef = useRef<Animation[]>([]);

  // 优化后的球配置数据
  const ballConfigs = useMemo<BallConfig[]>(
    () => [
      { url: ballUrls[5], className: 'left-[8px] top-[-4px] rotate-[79.914deg]' },
      {
        url: ballUrls[2],
        className: 'left-[130px] top-[-3px] rotate-[34.954deg]',
      },
      {
        url: ballUrls[4],
        className: 'right-[38px] top-[-18.3px] rotate-[-120.486deg]',
      },
      {
        url: ballUrls[2],
        className: 'left-[-72px] top-[40px] rotate-[-50.919deg]',
      },
      {
        url: ballUrls[1],
        className: 'left-[42px] top-[30px] rotate-[169.609deg]',
      },

      {
        url: ballUrls[5],
        className: 'right-[-60px] top-[85px] rotate-[119.438deg]',
      },
      {
        url: ballUrls[2],
        className: 'right-[-10px] top-[28.5px] rotate-[108.238deg]',
      },
      {
        url: ballUrls[0],
        className: 'right-[81px] top-[26px] rotate-[169.298deg]',
      },
      {
        url: ballUrls[3],
        className: 'left-[35px] top-[100px] rotate-[-59.388deg]',
      },
      { url: ballUrls[4], className: 'left-[115px] top-[61px] rotate-[-20.446deg]' },
      { url: ballUrls[0], className: 'left-[-28px] top-[80px] rotate-[81.71deg]' },
      {
        url: ballUrls[1],
        className: 'left-[187px] top-[100px] rotate-[93.368deg]',
      },
      {
        url: ballUrls[3],
        className: 'right-[20px] top-[95px] rotate-[-174.107deg]',
      },
    ],
    []
  );

  // 初始化球的引用
  useEffect(() => {
    if (containerRef.current) {
      ballsRef.current = containerRef.current.querySelectorAll('.ball-item');
    }
  }, []);

  // 计算动画参数
  const calculateAnimationParams = (
    ball: HTMLElement,
    index: number,
    containerRect: DOMRect
  ) => {
    const rect = ball.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const verticalPosition =
      (rect.top - containerRect.top) / containerRect.height;
    const isLeftSide = centerX < window.innerWidth / 2;
    const computedStyle = window.getComputedStyle(ball);
    const matrix = new DOMMatrix(computedStyle.transform);
    const currentRotate = Math.atan2(matrix.b, matrix.a) * (180 / Math.PI);
    const randomFactor = 0.85 + Math.random() * 0.3;
    const baseMoveScale = Math.min(window.innerWidth / 550, 1);

    let moveX, moveY;

    if (index < 3) {
      const baseMove = 40 * baseMoveScale;
      const baseVerticalMove = 60 * baseMoveScale;
      moveX = (index === 2 ? 1 : -1) * baseMove * randomFactor;
      moveY = -baseVerticalMove * (0.8 + Math.random() * 0.4);
    } else {
      const baseMove = 65 * baseMoveScale;
      const moveMultiplier = Math.max(0.3, 2.8 - verticalPosition * 2);
      moveX = (isLeftSide ? -1 : 1) * baseMove * moveMultiplier * randomFactor;

      const baseVerticalMove =
        (verticalPosition < 0.3 ? 100 : 25) * baseMoveScale;
      const verticalMultiplier = Math.max(0.2, 2.5 - verticalPosition * 2);
      moveY =
        (Math.random() > 0.5 ? 1 : -1) *
        baseVerticalMove *
        verticalMultiplier *
        randomFactor;
    }

    return { moveX, moveY, currentRotate };
  };

  // 创建动画关键帧
  const createKeyframes = (
    moveX: number,
    moveY: number,
    currentRotate: number
  ) => [
    { transform: `translate(0, 0) rotate(${currentRotate}deg)` },
    {
      transform: `translate(${moveX}px, ${moveY}px) rotate(${currentRotate + 8}deg)`,
      offset: 0.2,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    {
      transform: `translate(${moveX * 0.85}px, ${moveY * 0.6}px) rotate(${currentRotate + 5}deg)`,
      offset: 0.4,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    {
      transform: `translate(${moveX * 0.6}px, ${moveY * 0.3}px) rotate(${currentRotate + 3}deg)`,
      offset: 0.6,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    {
      transform: `translate(${moveX * 0.3}px, ${moveY * 0.15}px) rotate(${currentRotate + 1}deg)`,
      offset: 0.8,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    {
      transform: `translate(0, 0) rotate(${currentRotate}deg)`,
      offset: 1,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
  ];

  // 处理动画
  useEffect(() => {
    if (startPhysics && containerRef.current && ballsRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();

      ballsRef.current.forEach((ball, index) => {
        if (ball instanceof HTMLElement) {
          const { moveX, moveY, currentRotate } = calculateAnimationParams(
            ball,
            index,
            containerRect
          );

          const keyframes = new KeyframeEffect(
            ball,
            createKeyframes(moveX, moveY, currentRotate),
            {
              duration: ANIMATION_DURATION,
              easing: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
              fill: 'forwards',
              delay: index * ANIMATION_DELAY,
              composite: 'replace',
            }
          );

          const animation = new Animation(keyframes);
          animation.play();
          animationsRef.current.push(animation);
        }
      });
    }

    return () => {
      animationsRef.current.forEach((animation) => animation.cancel());
      animationsRef.current = [];
    };
  }, [startPhysics]);

  return (
    <div
      ref={containerRef}
      className="w-[375px] absolute bottom-[376px] left-0"
    >
      <div className="relative w-full">
        {ballConfigs.map((ball) => (
          <img
            key={ball.className}
            src={ball.url}
            alt="ball"
            className={clsx(
              'ball-item absolute w-[96px] h-[96px]',
              ball.className
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(BallContainer);
