import request from '.';

export enum DrawType {
  COUPON_DRAW = 'coupon_draw',
  FREE_DRAW = "free_draw",
  STAR_10 = "star_10",
  STAR_100 = "star_100",
  STAR_1500 = "star_1500"
}

export interface ICheckInfo {
  is_pass: boolean;
  free_draw_used_times: number,
  free_draw_unused_times: number,
  config_draw_max_times: number,
  owned_zoo_amount: number,
  owned_star_amount: number
  desc: string,
}

export interface IFreeDraw {
  attempt_number: number;
  zoo_required: number;
}

export interface IBasicMaterial {
  material_id: number,
  material_code: string,
  material_name: string,
  description: string,
  quantity: number
}

export interface IAdvancedMaterial extends IBasicMaterial {
  material_type: string;
  grade: string;
  star_level: number;
}

export interface IAdvancedStakeMaterial {
  quantity: number;
  advanced_material_id: number
}

export interface IPartyToken {
  party_id: number,
  partner_name: string,
  token_name: string,
  icon_url: string,
  party_icon_url: string,
  description: string,
  bot_link?: string,
  quantity: number
}

export interface IOwnedToken {
  party_id: number,
  partner_name: string;
  token_name: string,
  icon_url: string,
  party_icon_url: string,
  description: string,
  amount: number
}

export interface IStar {
  star_title: string;
  quantity: number;
}

export interface IDrawConfig {
  free_draw_times_config: Array<IFreeDraw>;
  basic_material_config: Array<IBasicMaterial>;
  party_tokens_list: Array<IPartyToken>;
}


export interface IDrawResult {
  advanced_material: Array<IAdvancedMaterial>;
  basic_material: Array<IBasicMaterial>;
  party_token: Array<IPartyToken>;
  star: Array<IStar>;
}

export interface IOwnedRewards {
  owned_basic_materials: Array<IBasicMaterial>;
  owned_advanced_materials: Array<IAdvancedMaterial>;
  owned_party_tokens: Array<IOwnedToken>;
  owned_staked_advanced_materials: Array<IAdvancedStakeMaterial>;
}

export interface ICommission {
  earned_star_info: {
    friends_deposi_amount: number;
    earned_by_inviting: number;
  }
}
export interface IRequirement extends IBasicMaterial {
  type: string;

}
export interface IMiniNode {
  id: number,
  name: string,
  grade: string,
  star_level: number,
  self_qty: number,
  energy: number,
  requirements: Array<IRequirement>
}

export enum MiniNodeMap {
  "Bronze" = 1,
  "Silver" = 2,
  "Gold" = 3,
  "Platinum" = 4,
}

export interface IMiniNodeConfig {
  "Bronze mininode": IMiniNode;
  "Silver mininode": IMiniNode;
  "Gold mininode": IMiniNode;
  "Platinum mininode": IMiniNode;
}

export interface DrawButtonType {
  draw_type: DrawType,
  draw_times: number,
  coupon_code?: string,
}

export interface ITickets {
  id: number;
  app_user_id: string;
  coupon_code: string;
  status: number;
  created_at: Date;
  expired_at: number
}

export const checkDraw = async (params: DrawButtonType) => {
  const res: ICheckInfo = await request('get', 'lottery/v1/check', { searchParams: { ...params } });
  return res;
};

export const getDrawConfig = async () => {
  const res: IDrawConfig = await request('get', 'lottery/v1/profile');
  return res;
};

export const getOwnedRewards = async () => {
  const res: IOwnedRewards = await request('get', 'lottery/v1/owned-rewards');
  return res;
};
export const getCommission = async () => {
  const res: ICommission = await request('get', 'lottery/v1/commission');
  return res;
};

export const lotteryDraw = async (params: DrawButtonType) => {
  const res: IDrawResult = await request('post', 'lottery/v1/draw', { json: { ...params } });
  return res;
};

export const getSynthesisConfig = async () => {
  const res: IMiniNodeConfig = await request('get', 'synthesis/v1/config');
  return res;
};

export const synthesisMiniNode = async ({ target_advanced_material_id, times }: { target_advanced_material_id: number, times: number }) => {
  const res: {
    synthesis_success: boolean
  } = await request('post', 'synthesis/v1/synthesis', { json: { target_advanced_material_id, times } });
  return res.synthesis_success;
};

export const getOwnedTickets = async () => {
  const res: Array<ITickets> = await request('get', 'lottery/v1/owned-coupons');
  return res;
}
