import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShowReward, { ShowRewardModalRef } from './ShowReward';
import images from '@/const/images';
import LackStarModal from './components/LackStarModal';
import InviteModal from './components/InviteModal';
import LackZooModal from './components/LackZooModal';
import DrawContainer from './components/DrawContainer';
import Header from '@/components/Header';
import VolumeModal from '@/components/Header/VolumeModal';
import Toast, { ToastRef } from '@/components/Toast';
import { useLocalStorageState, useMount, useRequest } from 'ahooks';
import {
  checkDraw,
  DrawButtonType,
  DrawType,
  getCommission,
  getOwnedTickets,
  ICheckInfo,
} from '@/api/draw';
import useDrawConfig from '@/hooks/useDrawConfig';
import { ModalRef } from '@/components/Modal';
import AnimationFrame from './animation';
import { useLoading } from '@/components/LoadingProvider';
import useSynthesis from '@/hooks/useSynthesis';
import { motion } from 'framer-motion';
import GetTicketModal from './components/GetTicketModal';
import TicketModal from './components/TicketModal';

const Draw = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoading } = useLoading();
  const showRewardRef = useRef<ShowRewardModalRef>();
  const lackStarModal = useRef<ModalRef>();
  const lackZooModal = useRef<ModalRef>();
  const inviteModal = useRef<ModalRef>();
  const volumeModal = useRef<ModalRef>();
  const broadcastingRef = useRef<ModalRef>();
  const ticketModalRef = useRef<ModalRef>();
  const toastRef = useRef<ToastRef>();
  const animationRef = useRef<AnimationFrame | null>(null);
  const [currentAmountZoo, setCurrentAmountZoo] = useState(0);
  const [isClaim, setIsClaim] = useLocalStorageState('rewardTickets', {
    defaultValue: false,
  });
  const { getCurrentFreeDraw } = useDrawConfig();
  const { materialList, refresh } = useSynthesis();

  const { data: commission } = useRequest(getCommission, {
    cacheKey: 'getCommission',
  });

  const { data: tickets, refresh: ticketRefresh } = useRequest(
    getOwnedTickets,
    {
      cacheKey: 'getOwnedTickets',
    }
  );

  const updateCurrentAmountZoo = (result: ICheckInfo, increase?: number) => {
    const usedTimes = result.free_draw_used_times + (increase ?? 1);
    const currentUseTimes =
      result.config_draw_max_times > usedTimes
        ? usedTimes
        : result.config_draw_max_times;
    const amount = getCurrentFreeDraw(currentUseTimes);
    setCurrentAmountZoo(amount);
  };

  const { data, runAsync: checkDrawAsync } = useRequest(
    async (value: DrawButtonType) => {
      try {
        const result = await checkDraw(value);
        return result;
      } catch (error: any) {
        toastRef.current?.show('error', error.message);
      }
    },
    {
      manual: true,
      cacheKey: 'checkDraw',
      onBefore: () => {
        if (!data) {
          setIsLoading(true);
        }
      },
      onFinally: () => {
        setIsLoading(false);
      },
    }
  );

  useMount(() => {
    animationRef.current = new AnimationFrame('hook-container');
    const mount = async () => {
      const res = await checkDrawAsync({
        draw_type: DrawType.FREE_DRAW,
        draw_times: 1,
      });
      res && updateCurrentAmountZoo(res);
    };
    mount();
  });

  const hasSynthesis = useMemo(() => {
    if (materialList) {
      return materialList.some((item) => item.maxSynthesis !== 0);
    }
    return false;
  }, [materialList]);

  const drawBefore = async (type: DrawButtonType) => {
    let isPass = true;
    try {
      const res = await checkDrawAsync({
        ...type,
        ...(type.draw_type === DrawType.COUPON_DRAW
          ? { coupon_code: tickets?.[0]?.coupon_code }
          : {}),
      });
      if (res) {
        if (type.draw_type === DrawType.FREE_DRAW) {
          if (currentAmountZoo === 0) {
            isPass = false;
            toastRef.current?.show(
              'error',
              'Data loading, please be patient and wait'
            );
          } else if (res.owned_zoo_amount < currentAmountZoo) {
            isPass = false;
            lackZooModal.current?.show();
          } else if (!res.is_pass && res.free_draw_used_times >= 20) {
            isPass = false;
            toastRef.current?.show(
              'error',
              'The number of free draws used up today'
            );
          }
        } else if (!res.is_pass) {
          isPass = false;
          if (type.draw_type === DrawType.COUPON_DRAW) {
            toastRef.current?.show('error', res.desc);
          } else {
            lackStarModal.current?.show();
          }
        }
      } else {
        isPass = false;
      }
    } catch (error: any) {
      toastRef.current?.show('error', error.message);
      isPass = false;
    }
    if (type.draw_type === DrawType.COUPON_DRAW) {
      return {
        isPass,
        code: tickets?.[0]?.coupon_code,
      };
    }
    return isPass;
  };

  const drawAfter = (type: DrawType) => {
    if (data && type === DrawType.FREE_DRAW) {
      updateCurrentAmountZoo(data, 2);
    }
    refresh();
  };

  useEffect(() => {
    if (tickets && tickets.length > 0 && !isClaim) {
      broadcastingRef.current?.show();
      setIsClaim(true);
    }
  }, [tickets, isClaim]);

  return (
    <div className="flex flex-col items-center w-full relative min-h-screen overflow-x-hidden">
      <Toast
        ref={toastRef}
        icon={
          <img
            src={images.draw.ticketInfo}
            className="w-[32px] h-[28px]"
            alt="ticket"
          />
        }
      />
      <VolumeModal volumeModal={volumeModal} />
      <Header volumeModal={volumeModal} isDraw />
      <DrawContainer
        showRewardRef={showRewardRef}
        inviteModal={inviteModal}
        toastRef={toastRef}
        animationRef={animationRef}
        currentAmountZoo={currentAmountZoo}
        currentTickets={tickets?.length ?? 0}
        ticketRefresh={ticketRefresh}
        onBefore={drawBefore}
        onAfter={drawAfter}
      />
      <div className="absolute left-[12px] top-[12vh] flex flex-col gap-[14px]">
        <img
          className="w-[50px] cursor-pointer"
          src={images.draw.bag}
          onClick={() => {
            navigate('/profile/Mininode', { state: location.pathname });
          }}
        />
        <div className="relative">
          <img
            className="w-[50px] cursor-pointer"
            src={images.draw.casting}
            onClick={() => {
              navigate('/draw/casting');
            }}
          />
          {hasSynthesis && (
            <div className="w-[12px] h-[12px] bg-[#F1322E] absolute right-0 top-[6px] rounded-full border-[2px] border-white border-solid"></div>
          )}
        </div>
      </div>
      <div className="absolute right-[6px] top-[12vh] flex flex-col gap-[14px]">
        <motion.div
          className="w-[58px] cursor-pointer"
          animate={{
            scale: [1, 1.05, 1],
            transition: {
              repeat: Infinity,
              ease: 'easeInOut',
              duration: 1.5,
            },
          }}
        >
          <img
            className="w-[58px] cursor-pointer"
            src={images.draw.tabiPool}
            onClick={() => {
              navigate('/draw/tabiPool');
            }}
          />
        </motion.div>
        <img
          className="w-[54px] object-contain cursor-pointer"
          src={images.game.tabiParty}
          onClick={() => {
            navigate('/tabiParty');
          }}
        />
        {tickets && tickets.length > 0 && (
          <img
            className="w-[50px] cursor-pointer"
            src={images.draw.ticket}
            onClick={() => {
              ticketModalRef.current?.show();
            }}
          />
        )}
      </div>
      <ShowReward ref={showRewardRef} />
      <LackStarModal lackStarModal={lackStarModal} />
      <LackZooModal lackZooModal={lackZooModal} />
      <InviteModal inviteModal={inviteModal} commission={commission} />
      <GetTicketModal
        broadcastingRef={broadcastingRef}
        rewardAmount={tickets?.length}
      />
      <TicketModal ticketModalRef={ticketModalRef} tickets={tickets} />
    </div>
  );
};

export default Draw;
