import React, { useEffect, useMemo, useRef } from 'react';
import { DrawButtonType, DrawType } from '@/api/draw';
import images from '@/const/images';
import clsx from 'clsx';
import { getDynamicBallUrls } from '@/utils/mininode';

type Props = {
  currentDrawType: DrawButtonType;
  currentAmountZoo: number;
  showCatchBall: number;
};

const ballUrls = getDynamicBallUrls();

const HookContainer: React.FC<Props> = ({
  currentDrawType,
  currentAmountZoo,
  showCatchBall,
}) => {
  const hookRef = useRef<HTMLImageElement>(null);
  const animationRef = useRef<Animation>();
  const prevDrawTypeRef = useRef<DrawButtonType>();

  const currentHook = useMemo(() => {
    switch (currentDrawType.draw_type) {
      case DrawType.STAR_10:
        return {
          url: images.draw.catch.hook2,
          coinUrl: images.draw.starCoin,
          quantity: 10,
        };
      case DrawType.STAR_100:
        return {
          url: images.draw.catch.hook2,
          coinUrl: images.draw.starCoin,
          quantity: 100,
        };
      case DrawType.STAR_1500:
        return {
          url: images.draw.catch.hook3,
          coinUrl: images.draw.starCoin,
          quantity: 1500 * currentDrawType.draw_times,
        };
      default:
        return {
          url: images.draw.catch.hook1,
          coinUrl: images.header.zooIcon,
          quantity: currentAmountZoo,
        };
    }
  }, [currentDrawType, currentAmountZoo]);

  // 钩子切换动画
  useEffect(() => {
    if (
      hookRef.current &&
      (prevDrawTypeRef.current?.draw_type !== currentDrawType.draw_type ||
        prevDrawTypeRef.current?.draw_times !== currentDrawType.draw_times)
    ) {
      // 取消之前的动画
      if (animationRef.current) {
        animationRef.current.cancel();
      }

      // 创建新的动画
      const keyframes = new KeyframeEffect(
        hookRef.current,
        [
          {
            transform: 'scale(1)',
          },
          {
            transform: 'scale(0.8)',
          },
          {
            transform: 'scale(1.2)',
          },
          {
            transform: 'scale(1)',
          },
        ],
        {
          duration: 400,
          easing: 'ease-out',
          fill: 'forwards',
          composite: 'replace',
        }
      );

      // 创建并播放动画
      animationRef.current = new Animation(keyframes);
      animationRef.current.play();

      prevDrawTypeRef.current = currentDrawType;
    }

    // 清理函数
    return () => {
      if (animationRef.current) {
        animationRef.current.cancel();
      }
    };
  }, [currentDrawType]);

  return (
    <div
      className={clsx('-translate-y-1/2 w-full origin-top')}
      id="hook-container"
    >
      <div ref={hookRef} className="flex flex-col relative items-center">
        <div className="w-[5px] h-[calc(100vh-380px)] ml-[2px] mb-[-2px] shrink-0 [background:linear-gradient(180deg,#A9B2A9_-3.48%,#C1CBC1_100%)] shadow-[1px_1px_2px_0px_rgba(0,0,0,0.25)_inset]" />
        <img
          src={currentHook.url}
          alt=""
          width={165}
          height={126}
          className="w-[165px] h-[126px]"
        />
        <div className="flex items-center gap-[4px] absolute bottom-[84px] left-1/2 -translate-x-1/2">
          <img src={currentHook.coinUrl} alt="" className="w-[20px] h-[20px]" />
          <span className="h-[20px] text-center font-changa-one text-[18px] leading-[normal] font-normal capitalize text-white">
            {currentHook.quantity}
          </span>
        </div>
        <img
          src={ballUrls[showCatchBall]}
          alt="ball"
          className={clsx(
            'w-[96px] h-[96px] absolute bottom-[-40px] left-1/2 -translate-x-1/2',
            showCatchBall !== -1 ? 'visible animate-catch' : 'invisible'
          )}
        />
      </div>
    </div>
  );
};

export default React.memo(HookContainer);
