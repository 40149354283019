/*
 * Created Date: December 13th 2024, 11:18:40 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 17th 2024, 12:04:57 am
 */
export const isTK =
  navigator.userAgent.includes('musical_ly') ||
  location.pathname.includes('tiktok');

export const isSnap =
  navigator.userAgent.includes('Snapchat') ||
  location.pathname.includes('snapchat');

export enum ThirdRoutes {
  TikTokLogin = '/tiktok/login',
  TikTok = '/tiktok',
  SnapChatLogin = '/snapchat/login',
  SnapChat = '/snapchat',
}
