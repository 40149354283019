import React from 'react';
import { DrawButtonType, DrawType } from '@/api/draw';
import images from '@/const/images';
import { useNavigate } from 'react-router-dom';
import useIsH5 from '@/hooks/useIsH5';
import ScaleButton from '@/components/ScaleButton';
import clsx from 'clsx';
import { openLinkInNewTab } from '@/utils';
import { postEvent } from '@telegram-apps/sdk-react';

const DrawBottom = ({
  currentAmountZoo,
  onDraw,
  getFreeDraw,
  onSwitch,
  currentDrawType,
  currentTickets,
}: {
  currentAmountZoo: number;
  currentDrawType: DrawButtonType;
  onDraw: () => void;
  getFreeDraw: () => void;
  currentTickets: number;
  onSwitch: (draw_type: DrawType) => void;
}) => {
  const navigate = useNavigate();
  const isH5 = useIsH5();
  const goReward = () => {
    navigate('/draw/reward');
  };

  const goGuide = () => {
    if (isH5) {
      openLinkInNewTab(
        'https://mirror.xyz/0xE407B76B219A0939658C0417c7BDC6ead63551D8/psxyGbBnuAdsYZOWhT4bV3ZoDKTDeaU2LMjulPoFCds?referrerAddress=0xE407B76B219A0939658C0417c7BDC6ead63551D8'
      );
    } else {
      postEvent('web_app_open_link', {
        url: 'https://mirror.xyz/0xE407B76B219A0939658C0417c7BDC6ead63551D8/psxyGbBnuAdsYZOWhT4bV3ZoDKTDeaU2LMjulPoFCds?referrerAddress=0xE407B76B219A0939658C0417c7BDC6ead63551D8',
      });
    }
  };

  return (
    <div className="absolute w-full h-[266px] flex flex-col bottom-0 left-0 bg-[url('@/assets/images/draw/catch/draw_bg1.png')] bg-no-repeat bg-cover bg-top">
      <div className="flex items-end justify-between h-[48px]">
        <div className="relative cursor-pointer" onClick={goReward}>
          <img
            src={images.draw.catch.reward}
            alt=""
            className="w-[88px] h-[24px]"
          />
          <div className="flex items-center pt-[2.5px] pl-[8px] justify-center gap-[2px] absolute top-0 left-0">
            <span className="text-[#668B5B] text-center font-changa-one text-[12px] font-normal leading-[normal] capitalize">
              Reward
            </span>
            <img
              src={images.draw.catch.gift}
              alt=""
              className="w-[20px] h-[20px]"
            />
          </div>
        </div>
        <ScaleButton
          className="w-[96px] h-[56px] flex items-center justify-center mr-[10px] mb-[10px]"
          onClick={onDraw}
        >
          <img
            src={images.draw.catch.btn}
            alt=""
            className="w-[96px] h-[56px]"
          />
        </ScaleButton>
        <div className="relative cursor-pointer" onClick={goGuide}>
          <img
            src={images.draw.catch.guideBg}
            alt=""
            className="w-[78px] h-[24px]"
          />
          <div className="flex items-center w-full pt-[2.5px] pr-[8px] justify-end gap-[2px] absolute top-0 left-0">
            <img
              src={images.draw.catch.guide}
              alt=""
              className="w-[20px] h-[20px]"
            />
            <span className="text-[#668B5B] text-center font-changa-one text-[12px] font-normal leading-[normal] capitalize">
              Guide
            </span>
          </div>
        </div>
      </div>

      <div className="w-[375px] flex flex-col mt-[-27.5px] items-center pt-[26px] px-[15px] flex-1 bg-[url('@/assets/images/draw/catch/draw_bg2.png')] bg-no-repeat bg-cover bg-top">
        {!isH5 && (
          <div
            onClick={getFreeDraw}
            className="flex cursor-pointer justify-center items-center gap-1 [background:#ECFBE1] shadow-[0px_1px_1px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(255,255,255,0.15)_inset] px-[8px] py-[4px] rounded-[10px]"
          >
            <img
              src={images.draw.catch.star}
              alt=""
              className="w-[12px] h-[12px]"
            />
            <div className="flex items-center gap-[3px] justify-center text-[#668B5B] font-poppins text-[10px] font-medium leading-[12px] capitalize">
              Get More Star
              <div className="w-fit overflow-hidden">
                <div className="animate-marquee">{'>>'}</div>
              </div>
            </div>
          </div>
        )}
        <div className="flex pt-[14px] justify-between">
          {isH5 && (
            <ScaleButton
              onClick={() => {
                if (currentTickets) {
                  onSwitch(DrawType.COUPON_DRAW);
                } else {
                  onSwitch(DrawType.FREE_DRAW);
                }
              }}
              className="w-[145px] h-[58px] cursor-pointer rounded-[8px] mx-auto flex flex-col gap-[6px] justify-center items-center shrink-0 bg-[linear-gradient(180deg,#B1D863_0%,#8ECF3E_100%)] stroke-[1px] stroke-white shadow-[0px_1px_2px_0px_rgba(255,255,255,0.08)_inset,0px_-5px_0px_0px_rgba(255,92,1,0.11)_inset] [filter:drop-shadow(0px_0.6px_1px_rgba(234,140,30,0.40))]"
            >
              <span className="text-white text-center [text-shadow:0px_1px_0px_rgba(0,0,0,0.15)] font-changa-one text-[16px] font-normal leading-[16px] capitalize">
                Basic draw
              </span>
              <div className="flex items-center gap-[2px]">
                <img
                  src={images.header.zooIcon}
                  alt=""
                  className="w-[12px] h-[12px]"
                />
                <span className="text-white text-center font-poppins text-[10px] font-normal leading-[14px] capitalize">
                  {currentAmountZoo}
                </span>
              </div>
              {currentTickets !== 0 && (
                <div className="flex h-[16px] absolute top-[-8px] right-0 items-center justify-center gap-[2px] border [background:rgba(255,255,255,0.80)] backdrop-blur-[4px] pl-[4px] pr-[6px] py-[2px] rounded-[8px] border-solid border-[rgba(160,203,87,0.20)]">
                  <img
                    src={images.draw.ticketInfo}
                    alt=""
                    className="w-[12px] h-[12px] -rotate-45 mt-[-2px]"
                  />
                  <span className="text-[#668B5B] font-poppins text-[10px] font-normal leading-[12px] capitalize">
                    {currentTickets}
                  </span>
                </div>
              )}
            </ScaleButton>
          )}

          {!isH5 && (
            <>
              <ScaleButton
                key={DrawType.FREE_DRAW}
                className="relative h-fit cursor-pointer"
                onClick={() => {
                  if (currentTickets) {
                    onSwitch(DrawType.COUPON_DRAW);
                  } else {
                    onSwitch(DrawType.FREE_DRAW);
                  }
                }}
              >
                <img
                  src={
                    currentDrawType.draw_type === DrawType.FREE_DRAW ||
                    currentDrawType.draw_type === DrawType.COUPON_DRAW
                      ? images.draw.catch.free
                      : images.draw.catch.freeBasic
                  }
                  alt=""
                  className="w-[118px] h-[58px]"
                />
                <div className="flex flex-col items-center w-full h-full  justify-center gap-[7px] absolute top-0 left-0">
                  <span
                    className={clsx(
                      'text-center font-changa-one text-[12px] font-normal leading-[13px] capitalize',
                      currentDrawType.draw_type === DrawType.FREE_DRAW ||
                        currentDrawType.draw_type === DrawType.COUPON_DRAW
                        ? 'text-white [text-shadow:0px_1px_0px_rgba(0,0,0,0.15)]'
                        : 'text-[#668B5B]'
                    )}
                  >
                    Basic draw
                  </span>
                  <div className="flex items-center gap-[2px]">
                    <img
                      src={images.header.zooIcon}
                      alt=""
                      className="w-[12px] h-[12px]"
                    />
                    <span
                      className={clsx(
                        'text-center font-poppins text-[10px] font-normal leading-[14px] capitalize',
                        currentDrawType.draw_type === DrawType.FREE_DRAW ||
                          currentDrawType.draw_type === DrawType.COUPON_DRAW
                          ? 'text-white '
                          : 'text-[#979797]'
                      )}
                    >
                      {currentAmountZoo}
                    </span>
                  </div>
                </div>
                {currentTickets !== 0 && (
                  <div className="flex h-[16px] absolute top-[-8px] right-0 items-center justify-center gap-[2px] border [background:rgba(255,255,255,0.80)] backdrop-blur-[4px] pl-[4px] pr-[6px] py-[2px] rounded-[8px] border-solid border-[rgba(160,203,87,0.20)]">
                    <img
                      src={images.draw.ticketInfo}
                      alt=""
                      className="w-[12px] h-[12px] -rotate-45 mt-[-2px]"
                    />
                    <span className="text-[#668B5B] font-poppins text-[10px] font-normal leading-[12px] capitalize">
                      {currentTickets}
                    </span>
                  </div>
                )}
              </ScaleButton>

              <ScaleButton
                key={DrawType.STAR_10}
                className="relative h-fit ml-[-4px] cursor-pointer"
                onClick={() => {
                  if (currentDrawType.draw_type === DrawType.STAR_10) {
                    onSwitch(DrawType.STAR_100);
                  } else {
                    onSwitch(DrawType.STAR_10);
                  }
                }}
              >
                <img
                  src={
                    currentDrawType.draw_type === DrawType.STAR_10 ||
                    currentDrawType.draw_type === DrawType.STAR_100
                      ? images.draw.catch.draw
                      : images.draw.catch.drawBasic
                  }
                  alt=""
                  className="w-[120px] h-[58px]"
                />
                <div className="flex flex-col items-center w-full h-full  justify-center gap-[7px] absolute top-0 left-0 ">
                  <span
                    className={clsx(
                      'text-center font-changa-one text-[12px] font-normal leading-[13px] capitalize',
                      currentDrawType.draw_type === DrawType.STAR_10 ||
                        currentDrawType.draw_type === DrawType.STAR_100
                        ? 'text-white [text-shadow:0px_1px_0px_rgba(0,0,0,0.15)]'
                        : 'text-[#668B5B]'
                    )}
                  >
                    Senior draw
                  </span>
                  <div className="flex items-center gap-[2px]">
                    <img
                      src={images.draw.starCoin}
                      alt=""
                      className="w-[12px] h-[12px]"
                    />
                    <span
                      className={clsx(
                        'text-center font-poppins text-[10px] font-normal leading-[14px] capitalize',
                        currentDrawType.draw_type === DrawType.STAR_10 ||
                          currentDrawType.draw_type === DrawType.STAR_100
                          ? 'text-white '
                          : 'text-[#979797]'
                      )}
                    >
                      {currentDrawType.draw_type === DrawType.STAR_100
                        ? 100
                        : 10}
                    </span>
                  </div>
                </div>
                <div
                  className={clsx(
                    'w-[46px] h-[16px] absolute right-[-4px] pl-[2px] backdrop-blur-[4px] pr-[3px] flex font-changa-one text-[10px] font-normal leading-[12px] capitalize items-center justify-between top-[-8px] shrink-0 border [background:rgba(255,255,255,0.80)] rounded-[8px_8px_8px_2px] border-solid',
                    currentDrawType.draw_type !== DrawType.STAR_10 &&
                      currentDrawType.draw_type !== DrawType.STAR_100
                      ? 'border-[rgba(160,203,87,0.20)]'
                      : 'border-[rgba(255,169,30,0.20)]'
                  )}
                >
                  <div
                    className={clsx(
                      'flex h-[12px] [flex:2] text-center justify-center items-center transition-all duration-300',
                      currentDrawType.draw_type !== DrawType.STAR_10 &&
                        currentDrawType.draw_type !== DrawType.STAR_100 &&
                        '[background:#A0CB57] text-white rounded-[6px]',
                      currentDrawType.draw_type === DrawType.STAR_100 &&
                        'text-[#FFA91E]',
                      currentDrawType.draw_type === DrawType.STAR_10 &&
                        '[background:#FFA91E] text-white rounded-[6px]'
                    )}
                  >
                    ×1
                  </div>
                  <div
                    className={clsx(
                      'flex h-[12px] [flex:3] justify-center items-center transition-all duration-300',
                      currentDrawType.draw_type !== DrawType.STAR_10 &&
                        currentDrawType.draw_type !== DrawType.STAR_100 &&
                        'text-[#A0CB57]',
                      currentDrawType.draw_type === DrawType.STAR_10 &&
                        'text-[#FFA91E]',
                      currentDrawType.draw_type === DrawType.STAR_100 &&
                        '[background:#FFA91E] text-white rounded-[6px]'
                    )}
                  >
                    ×10
                  </div>
                </div>
              </ScaleButton>
              <ScaleButton
                key={DrawType.STAR_1500}
                className="relative h-fit ml-[-4.5px] cursor-pointer"
                onClick={() => {
                  onSwitch(DrawType.STAR_1500);
                }}
              >
                <img
                  src={
                    currentDrawType.draw_type === DrawType.STAR_1500
                      ? images.draw.catch.senior
                      : images.draw.catch.seniorBasic
                  }
                  alt=""
                  className="w-[118px] h-[58px]"
                />
                <div className="flex flex-col items-center w-full h-full justify-center gap-[7px] absolute top-0 left-0">
                  <span
                    className={clsx(
                      'text-center font-changa-one text-[12px] font-normal leading-[13px] capitalize',
                      currentDrawType.draw_type === DrawType.STAR_1500
                        ? 'text-white [text-shadow:0px_1px_0px_rgba(0,0,0,0.15)]'
                        : 'text-[#668B5B]'
                    )}
                  >
                    Supreme draw
                  </span>
                  <div className="flex items-center gap-[2px]">
                    <img
                      src={images.draw.starCoin}
                      alt=""
                      className="w-[12px] h-[12px]"
                    />
                    <span
                      className={clsx(
                        'text-center font-poppins text-[10px] font-normal leading-[14px] capitalize',
                        currentDrawType.draw_type === DrawType.STAR_1500
                          ? 'text-white '
                          : 'text-[#979797]'
                      )}
                    >
                      {currentDrawType.draw_type === DrawType.STAR_1500
                        ? currentDrawType.draw_times * 1500
                        : 1500}
                    </span>
                  </div>
                </div>
                <div
                  className={clsx(
                    'w-[58px] h-[16px] absolute px-[2px] right-[-8px] flex font-changa-one text-[10px] font-normal leading-[12px] capitalize items-center justify-between top-[-8px] shrink-0 border backdrop-blur-[4px] [background:rgba(255,255,255,0.80)] rounded-[8px_8px_8px_2px] border-solid',
                    currentDrawType.draw_type !== DrawType.STAR_1500
                      ? 'border-[rgba(160,203,87,0.20)]'
                      : 'border-[rgba(247,95,33,0.20)]'
                  )}
                >
                  <div
                    className={clsx(
                      'flex h-[12px] [flex:2] text-center justify-center items-center transition-all duration-300',
                      currentDrawType.draw_type !== DrawType.STAR_1500 &&
                        '[background:#A0CB57] text-white rounded-[6px]',
                      currentDrawType.draw_type === DrawType.STAR_1500 &&
                        'text-[#FF6F34]',
                      currentDrawType.draw_type === DrawType.STAR_1500 &&
                        currentDrawType.draw_times === 1 &&
                        '[background:#FF6F34] text-white rounded-[6px]'
                    )}
                  >
                    ×10
                  </div>
                  <div
                    className={clsx(
                      'flex h-[12px] [flex:3] justify-center items-center transition-all duration-300',
                      currentDrawType.draw_type !== DrawType.STAR_1500 &&
                        'text-[#A0CB57]',
                      currentDrawType.draw_type === DrawType.STAR_1500 &&
                        'text-[#FF6F34]',
                      currentDrawType.draw_type === DrawType.STAR_1500 &&
                        currentDrawType.draw_times === 10 &&
                        '[background:#FF6F34] text-white rounded-[6px]'
                    )}
                  >
                    ×100
                  </div>
                </div>
              </ScaleButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(DrawBottom);
