/*
 * Created Date: December 10th 2024, 4:31:40 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 10th 2024, 4:31:40 pm
 */

const tabiZooUserTokenKey = 'tabi-zoo-user-token';

export const getToken = () => {
  return localStorage.getItem(tabiZooUserTokenKey);
};

export const setToken = (token: string) => {
  localStorage.setItem(tabiZooUserTokenKey, token);
};

export const clearToken = () => {
  localStorage.removeItem(tabiZooUserTokenKey);
};
