import { getMiniNodeList } from '@/api/mininode';
import { useRequest } from 'ahooks';

/*
 * Created Date: December 25th 2024, 8:50:56 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 25th 2024, 8:50:56 pm
 */
export default function useMiniNodeList() {
  return useRequest(getMiniNodeList, {
    cacheKey: 'mini-node-list',
  });
}
