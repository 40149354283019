import { FixedLayout } from '@telegram-apps/telegram-ui';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import images from '@/const/images';
import useIsSpin from '@/hooks/useIsSpin';
import logEvent, { EventName } from '@/utils/firebase';

interface Tabbar {
  name: string;
  path: string;
  icon: string;
  selectedIcon: string;
  round?: boolean;
}

const tabbarList: Tabbar[] = [
  {
    name: 'Shiro',
    path: '/index',
    icon: images.tabbar.gameIcon,
    selectedIcon: images.tabbar.gameSelectedIcon,
  },
  {
    name: 'Draw',
    path: '/draw',
    icon: images.tabbar.drawIcon,
    selectedIcon: images.tabbar.drawIcon,
  },
  {
    name: 'Spin',
    path: '/spin',
    icon: images.tabbar.spinIcon,
    selectedIcon: images.tabbar.spinSelectedIcon,
  },
];

const Tabbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSpin } = useIsSpin();

  const handleLink = (tabbar: Tabbar) => {
    if (location.pathname === tabbar.path) return;
    navigate(tabbar.path);
    logEvent(EventName.GLOBAL_MAIN_TAB_CLICK, {
      tab_name: `${tabbar.name}`,
    });
  };

  return (
    <FixedLayout
      vertical="bottom"
      className="w-full bg-[linear-gradient(_to_bottom,_transparent,_rgba(255,255,255,1)_)] bg-[100%_100%]"
    >
      <div className="flex justify-between items-center rounded-[20px] w-[339px] h-[60px] shrink-0 bg-white shadow-[0px_1px_4px_0px_rgba(0,0,0,0.40),0px_-2px_1px_0px_#C6DBCD_inset] mx-auto mb-[20px] px-[3px] text-[#808080] font-rubik ">
        {tabbarList?.map((tabbar) => (
          <div
            key={tabbar.name}
            className={clsx(
              'w-[80px] relative cursor-pointer flex-1 h-[64px] flex flex-col justify-center gap-[5px] items-center text-[10px] rounded-2xl',
              location.pathname === tabbar.path && 'text-[#ff5c01]'
            )}
            onClick={() => {
              handleLink(tabbar);
            }}
          >
            <div className={clsx('flex flex-col justify-center items-center')}>
              {tabbar.name === tabbarList[1].name ? (
                <>
                  <img
                    className="w-[47.385px] absolute left-1/2 top-0 -translate-y-[6px] -translate-x-1/2 animate-float"
                    width={47}
                    height={47}
                    src={
                      location.pathname === tabbar.path
                        ? tabbar.selectedIcon
                        : tabbar.icon
                    }
                  />
                  <div className="w-[30px] h-[30px]"></div>
                </>
              ) : (
                <img
                  className="w-[30px]"
                  width={30}
                  height={30}
                  src={
                    location.pathname === tabbar.path
                      ? tabbar.selectedIcon
                      : tabbar.icon
                  }
                />
              )}
              {tabbar.name}

              {isSpin && tabbar.name === tabbarList[2].name && (
                <div className="w-[10px] h-[10px] bg-[#FF5C01] absolute right-[38px] top-[10px] rounded-full border-[2px] border-white border-solid"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </FixedLayout>
  );
};

export default Tabbar;
