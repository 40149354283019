import { ICommission } from '@/api/draw';
import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const InviteModal = ({
  inviteModal,
  commission,
}: {
  inviteModal: React.MutableRefObject<ModalRef | undefined>;
  commission: ICommission | undefined;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Modal ref={inviteModal} icon={images.draw.moreStar}>
      <div className="w-[339px] pb-[30px] flex flex-col gap-[20px] items-center shrink-0 text-black [background:linear-gradient(180deg,rgba(255,224,85,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[50px]">
        <h3 className="text-[20px] font-changa-one text-black leading-[20px]">
          Get More Star
        </h3>
        <div className="w-[299px] flex flex-col gap-[12px]">
          <div
            onClick={() => {
              navigate('/invite', { state: location.pathname });
            }}
            className="flex flex-col cursor-pointer items-start gap-[8px] [background:rgba(0,0,0,0.05)] p-[12px] rounded-[16px]"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-[4px]">
                <img
                  src={images.draw.moreStarReward}
                  alt="reward"
                  className="w-[24px] h-[24px]"
                />
                <span className="text-black font-changa-one text-[16px] font-normal leading-[20px]">
                  Invitation reward
                </span>
              </div>
              <img
                src={images.guide.joinCommunity.communityLinkIcon}
                alt=""
                className="w-[16px] h-[16px]"
              />
            </div>
            <p className="font-poppins text-black opacity-30 leading-[20px] text-[14px]">
              Earn 1 free spin for every 100 Star your invited friends deposit.
            </p>
            <div className="flex items-center justify-between gap-[8px]">
              <div className="flex flex-1 flex-col justify-between items-center border [background:rgba(0,0,0,0.04)] shadow-[0px_2px_0px_0px_rgba(0,0,0,0.03)_inset] px-[12px] py-[8px] rounded-[8px] border-solid border-[#FDFDFF]">
                <span className="text-[#F75F21] font-changa-one text-[18px] font-normal leading-[20px] capitalize">
                  {commission?.earned_star_info?.friends_deposi_amount || 0}
                </span>
                <span className="font-changa-one text-center text-[#3E3E3E] text-[12px] font-normal leading-[14px]">
                  Friends’ Star deposit amount
                </span>
              </div>
              <div className="flex flex-1 flex-col justify-between items-center border [background:rgba(0,0,0,0.04)] shadow-[0px_2px_0px_0px_rgba(0,0,0,0.03)_inset] px-[12px] py-[8px] rounded-[8px] border-solid border-[#FDFDFF]">
                <span className="text-[#F75F21] font-changa-one text-[18px] font-normal leading-[20px] capitalize">
                  {commission?.earned_star_info?.earned_by_inviting || 0}
                </span>
                <span className="font-changa-one text-center text-[#3E3E3E] text-[12px] font-normal leading-[14px]">
                  Star earned by inviting
                </span>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate('/tabiParty');
            }}
            className="flex flex-col cursor-pointer items-start gap-[8px] [background:rgba(0,0,0,0.05)] p-[12px] rounded-[16px]"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-[4px]">
                <img
                  src={images.draw.moreStarTask}
                  alt="reward"
                  className="w-[24px] h-[24px]"
                />
                <span className="text-black font-changa-one text-[16px] font-normal leading-[20px]">
                  Complete project tasks
                </span>
              </div>
              <img
                src={images.guide.joinCommunity.communityLinkIcon}
                alt=""
                className="w-[16px] h-[16px]"
              />
            </div>
            <p className="font-poppins text-black opacity-30 leading-[20px] text-[14px]">
              Go to Tabiparty to view the tasks of each partner and complete
              them
            </p>
          </div>
          <div
            onClick={() => {
              navigate('/star/recharge', { state: location.pathname });
            }}
            className="flex flex-col cursor-pointer items-start gap-[8px] [background:rgba(0,0,0,0.05)] p-[12px] rounded-[16px]"
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center gap-[4px]">
                <img
                  src={images.header.starIcon}
                  alt="reward"
                  className="w-[24px] h-[24px]"
                />
                <span className="text-black font-changa-one text-[16px] font-normal leading-[20px]">
                  Recharge stars
                </span>
              </div>
              <img
                src={images.guide.joinCommunity.communityLinkIcon}
                alt=""
                className="w-[16px] h-[16px]"
              />
            </div>
            <p className="font-poppins text-black opacity-30 leading-[20px] text-[14px]">
              Get more rewards
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(InviteModal);
