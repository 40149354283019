import { getRewardProjects, Status, TaskType } from '@/api/mine';
import { useRequest } from 'ahooks';
import store from 'store';
import React, { useState } from 'react';
import { getTaskList } from '@/api/task';

const NewDot = () => {
  const [showNew, setShowNew] = useState(false);
  const [showTaskDot, setShowTaskDot] = useState(false);
  useRequest(getRewardProjects, {
    onSuccess: (data) => {
      const projectNames = data.map((item) => item.project_name);
      if (store.get('rewardProject')) {
        const hasNew = !!projectNames?.find(
          (item) => !store.get('rewardProject').includes(item)
        );
        if (hasNew) {
          setShowNew(true);
          store.set('rewardProject', projectNames);
        }
      } else {
        store.set('rewardProject', projectNames);
        setShowNew(true);
      }
    },
    cacheKey: 'rewardProjects',
  });

  useRequest(getTaskList, {
    onSuccess: (data) => {
      let showTaskDot = false;

      data?.forEach((item) => {
        item.task_list?.forEach((i) => {
          if (
            i.user_task_status === Status.NotFinish &&
            i.task_type !== TaskType.Invite3Person
          ) {
            showTaskDot = true;
          }
        });
      });

      setShowTaskDot(showTaskDot);
    },
    cacheKey: 'taskList',
  });
  return (
    <>
      {showNew && (
        <div className="w-[22px] py-[1.5px] shrink-0 [background:#F1322E] shadow-[0px_0.4px_1px_0px_rgba(0,0,0,0.34),0px_-1.2px_0px_0px_rgba(0,0,0,0.13)_inset] rounded-[6px_6px_6px_0px] absolute right-[8px] top-[6px] translate-x-2/3 -translate-y-1/2 text-white text-center font-changa-one text-[8px] font-normal leading-[10px] tracking-[-0.408px] capitalize">
          New
        </div>
      )}
      {!showNew && showTaskDot && (
        <div className="w-[8px] h-[8px] bg-[#FF5C01] [filter:drop-shadow(0.4px_0.4px_0px_#A13C04)] absolute right-[4px] top-[1px] rounded-full"></div>
      )}
    </>
  );
};

export default React.memo(NewDot);
