import { getSynthesisConfig, IMiniNodeConfig } from "@/api/draw";
import { IRouterRootLoaderData } from "@/router";
import { MininodeType } from "@/utils/mininode";
import { useRequest, useMount } from "ahooks";
import { useMemo } from "react";
import { useAsyncValue } from "react-router-dom";
import useOwnedRewards from "./useOwnedRewards";

export type MiniNodeConfigType = {
  name: string;
  maxSynthesis: number;
  available: number,
  stake: number
  url?: string;
  synthesisMaterials: Array<{
    materialId: number;
    name: string;
    quantity: number;
    ownedQuantity: number;
    url?: string;
  }>
}

const miniNodes = ["Bronze mininode", "Silver mininode", "Gold mininode", "Platinum mininode"] as Array<keyof IMiniNodeConfig>

const useSynthesis = () => {
  const { ownedRewards, refresh } = useOwnedRewards();
  const loaderData = useAsyncValue() as IRouterRootLoaderData;

  const { data, run } = useRequest(getSynthesisConfig, {
    manual: true,
    cacheKey: 'synthesisConfig',
  });

  useMount(() => {
    if (!loaderData?.synthesisMaterials) {
      run();
    }
  });

  const values = useMemo(() => {
    if (loaderData && loaderData.synthesisMaterials) return loaderData?.synthesisMaterials;

    return data;
  }, [loaderData?.synthesisMaterials, data]);

  const materialList = useMemo(() => {
    const result: MiniNodeConfigType[] = []
    if (ownedRewards && values) {
      const { basicMaterials, advancedMaterials } = ownedRewards;

      for (const key of miniNodes) {
        const ele = values[key]
        let parent: MiniNodeConfigType = {
          name: advancedMaterials[ele.id - 1].name,
          url: advancedMaterials[ele.id - 1].url,
          available: advancedMaterials[ele.id - 1].available || 0,
          stake: advancedMaterials[ele.id - 1].stake || 0,
          maxSynthesis: -1,
          synthesisMaterials: []
        }

        ele.requirements.forEach(item => {
          const material = item.type === `${MininodeType.BASIC}_material` ? basicMaterials[item.material_id] : item.type === `${MininodeType.ADVANCED}_material` ? advancedMaterials[item.material_id - 1] : null
          if (material) {
            const ownedQuantity = material.available ?? (material.amount || 0)
            parent.maxSynthesis = parent.maxSynthesis < 0 ? (ownedQuantity) / item.quantity : Math.min(parent.maxSynthesis, (ownedQuantity) / item.quantity)
            parent.synthesisMaterials.push({
              materialId: item.material_id,
              name: material.name,
              ownedQuantity,
              quantity: item.quantity,
              url: material.url
            })
          }
        })

        parent.maxSynthesis = parent.maxSynthesis < 1 ? 0 : Math.floor(parent.maxSynthesis)
        result.push(parent)
      }
    }

    return result

  }, [ownedRewards, values])

  return {
    materialList,
    refresh
  }
}

export default useSynthesis
