import React, { forwardRef, useImperativeHandle, useState } from 'react';
import images from '@/const/images';
import clsx from 'clsx';

export interface ModalRef {
  show: () => void;
  hide: () => void;
}

const Modal = forwardRef(
  (
    {
      icon,
      children,
      iconChildren,
      onClose,
      hideClose = false,
    }: {
      icon?: string;
      children: React.ReactNode;
      iconChildren?: React.ReactNode;
      onClose?: () => void;
      hideClose?: boolean;
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => {
      const show = () => {
        setVisible(true);
      };

      const hide = () => {
        setVisible(false);
      };

      return { show, hide };
    }, []);

    return (
      <div
        className={clsx(
          visible
            ? 'fixed w-[375px] h-screen backdrop-blur-[5px] z-[100] flex justify-center items-center left-1/2 -translate-x-1/2 top-0 bg-[#000000b2]'
            : 'hidden'
        )}
      >
        <div className="relative">
          {iconChildren || (
            <img
              className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[100px]"
              src={icon}
            />
          )}
          {!hideClose && (
            <img
              className="absolute top-[-15px] right-[15px] w-[35px] cursor-pointer"
              src={images.common.close}
              onClick={() => {
                const isReturn = onClose?.();
                if (isReturn) return;
                setVisible(false);
              }}
            />
          )}
          {children}
        </div>
      </div>
    );
  }
);

export default Modal;
