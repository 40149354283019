/*
 * Created Date: December 30th 2024, 4:16:30 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 30th 2024, 4:16:30 pm
 */

const regexp = /(?:\.0*|(\.\d+?)0+)$/;

export const formatNumber = (num: string) => {
  return num.replace(regexp, '$1');
};

export const padZero = (num: number) => {
  const str = num + '';

  return str.length <= 1 ? str.padStart(2, '0') : str;
};
