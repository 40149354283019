import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import images from '@/const/images';
import clsx from 'clsx';
import { MaterialType, mininodeIcons, MininodeType } from '@/utils/mininode';
import RewardsContainer from './components/RewardsContainer';

export interface ShowRewardModalRef {
  show: (value: MaterialType[], onClose?: () => void) => void;
  hide: () => void;
}

const ShowReward = forwardRef((_, ref) => {
  const [visible, setVisible] = useState(false);
  const functionRef = useRef<() => void | undefined>();
  const [rewardList, setRewardList] = useState<MaterialType[]>([]);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hasPartyToken = useMemo(() => {
    const partyToken = rewardList?.find(
      (item) => item.type === MininodeType.PARTY
    );
    return partyToken;
  }, [rewardList]);

  const videoEnded = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const hide = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    if (functionRef.current) {
      functionRef.current();
      functionRef.current = void 0;
    }
    setRewardList([]);
    setVisible(false);
  };

  useImperativeHandle(ref, () => {
    const show = (value: MaterialType[], onClose?: () => void) => {
      setRewardList(value);
      setVisible(true);
      if (videoRef.current) {
        videoRef.current.play();
      }
      if (onClose) {
        functionRef.current = onClose;
      }
    };

    return { show, hide };
  }, []);

  const hasLight = useMemo(() => {
    if (rewardList.length === 0) return false;
    if (rewardList.length < 2) {
      const hasLightMiniNodes = mininodeIcons.slice(3);
      return (
        rewardList[0].type === MininodeType.BASIC &&
        hasLightMiniNodes.includes(rewardList[0].name)
      );
    }
    return false;
  }, [rewardList]);
  const disableControls = () => {
    if (videoRef.current) {
      videoRef.current.controls = false;
    }
  };

  const isHigher = useMemo(()=>{
    if(rewardList.length === 1){
      return rewardList[0].type === MininodeType.ADVANCED
    }

    return rewardList.length > 1
  },[rewardList])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('canplay', disableControls);
      videoRef.current.addEventListener('ended', videoEnded);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', disableControls);
        videoRef.current.removeEventListener('ended', videoEnded);
      }
    };
  }, []);

  return (
    <div
      className={clsx(
        'fixed w-[375px] h-screen backdrop-blur-[5px] z-[99999] flex justify-center items-center left-1/2 top-0 -translate-x-1/2 overflow-hidden',
        visible ? 'opacity-100' : 'opacity-0 [visibility:hidden]'
      )}
    >
      <div className="relative flex flex-col items-center justify-center pt-[20px] w-full h-full pb-safe-or-[36px] px-[42px] gap-[24px] ">
        <video
          ref={videoRef}
          src="/video/draw.mp4"
          muted
          playsInline
          controls={false}
          disablePictureInPicture
          loop={false}
          width={375}
          preload="auto"
          className="absolute mix-blend-screen z-0 top-0 left-0 w-full opacity-85 h-full object-cover pointer-events-none"
        ></video>
        <div className="flex flex-col items-center relative z-1 w-full">
          <img
            src={images.draw.streamers}
            alt=""
            className={clsx(
              'w-[292px] absolute -top-[90px]',
              visible ? 'animate-show-streams-scale' : 'scale-0'
            )}
          />
          <img
            src={images.draw.shiro}
            alt=""
            className={clsx(
              'w-[188px]',
              visible ? 'animate-show-draw-shiro' : '-translate-y-1/2 opacity-0'
            )}
          />
          <span
            className={clsx(
              'absolute bottom-0 w-[291px] text-white text-center font-changa-one text-[28px] font-normal leading-[32px] uppercase',
              isHigher &&
                '[-webkit-text-stroke-width:1.33px] [-webkit-text-stroke-color:#FF5C01]',
              visible ? 'animate-show' : 'opacity-0'
            )}
          >
            Congratulations
          </span>
        </div>
        <div
          className={clsx(
            'flex flex-col items-center gap-[24px] max-h-[400px] w-[375px] relative z-1',
            visible ? 'animate-show' : 'opacity-0'
          )}
        >
          <RewardsContainer
            hasPartyToken={hasPartyToken}
            list={rewardList}
            hasLight={hasLight}
          />
        </div>

        <div
          className={clsx(
            'flex flex-col gap-[16px] w-full relative z-1',
            visible ? 'animate-show' : 'opacity-0'
          )}
        >
          <div
            onClick={hide}
            className="cursor-pointer flex w-full h-[46px] justify-center items-center self-stretch [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-[47px] text-white font-changa-one text-[16px] font-normal leading-[14px] capitalize"
          >
            Claim
          </div>
          <span className="w-[291px] opacity-50 text-white text-center font-poppins text-[12px] font-normal leading-[14px]">
            Tips：You can check your rewards in the “Bag”
          </span>
        </div>
      </div>
    </div>
  );
});

export default ShowReward;
