/*
 * Created Date: December 9th 2024, 7:35:11 pm
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 10th 2024, 11:24:09 am
 */

import { setToken } from '@/utils/token';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Callback() {
  let [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (searchParams.has('token')) {
        setToken(searchParams.get('token')!);

        navigate('/index', { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  }, []);
  return null;
}
