import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DrawButtonType, DrawType, lotteryDraw } from '@/api/draw';
import { formatMaterial, MaterialType } from '@/utils/mininode';
import { useRequest } from 'ahooks';
import { ShowRewardModalRef } from '../ShowReward';
import { ToastRef } from '@/components/Toast';
import { ModalRef } from '@/components/Modal';
import RewardTip, { RewardTipRef } from './RewardTip';
import useProfile from '@/hooks/useProfile';
import useIsH5 from '@/hooks/useIsH5';
import useVolumeSound from '@/hooks/useVolumeSound';
import AnimationFrame from '../animation';
import BallContainer from './BallContainer';
import DrawBottom from './DrawBottom';
import HookContainer from './HookContainer';
import { useLoading } from '@/components/LoadingProvider';
import { VisibleType } from '../type';
import dayjs from '@/utils/dayjs';
import store from 'store';

type Props = {
  showRewardRef: React.MutableRefObject<ShowRewardModalRef | undefined>;
  inviteModal: React.MutableRefObject<ModalRef | undefined>;
  toastRef: React.MutableRefObject<ToastRef | undefined>;
  animationRef: React.MutableRefObject<AnimationFrame | null>;
  currentAmountZoo: number;
  currentTickets: number;
  onBefore: (type: DrawButtonType) => Promise<
    | boolean
    | {
        isPass: boolean;
        code: string | undefined;
      }
  >;
  onAfter: (type: DrawType) => void;
  ticketRefresh: () => void;
};

const DrawContainer: React.FC<Props> = ({
  showRewardRef,
  inviteModal,
  toastRef,
  animationRef,
  currentAmountZoo,
  currentTickets,
  onBefore,
  onAfter,
  ticketRefresh,
}) => {
  const { playAudio: playBetAudio } = useVolumeSound('/audio/spin/bet.mp3');
  const { playAudio: playSpinAudio } = useVolumeSound('/audio/spin/spin.mp3');
  const { playAudio: playDrawAudio } = useVolumeSound('/audio/draw/spin.mp3');
  const { run } = useProfile();
  const { setIsLoading } = useLoading();
  const isH5 = useIsH5();
  const rewardTipRef = useRef<RewardTipRef>();
  const handleRef = useRef<number | null>(null);
  const isAnimationFinishedRef = useRef(true);
  const hasBallRef = useRef(false);
  const [data, setData] = useState<MaterialType[]>();
  const [currentDrawType, setCurrentDrawType] = useState<DrawButtonType>({
    draw_type: DrawType.FREE_DRAW,
    draw_times: 1,
  });
  const [showCatchBall, setShowCatchBall] = useState(-1);
  const debounceRef = useRef(false);
  const [startPhysics, setStartPhysics] = useState(false);

  useEffect(() => {
    if (currentTickets) {
      setCurrentDrawType({ draw_type: DrawType.COUPON_DRAW, draw_times: 1 });
    } else {
      setCurrentDrawType((pre) => {
        if (
          pre.draw_type === DrawType.COUPON_DRAW ||
          pre.draw_type === DrawType.FREE_DRAW
        ) {
          return { draw_type: DrawType.FREE_DRAW, draw_times: 1 };
        }
        return pre;
      });
    }
  }, [currentTickets]);
  const onClose = () => {
    debounceRef.current = false;
    onAfter(currentDrawType.draw_type);
    if (currentDrawType.draw_type === DrawType.COUPON_DRAW) {
      currentTickets === 0 &&
        setCurrentDrawType({ draw_type: DrawType.FREE_DRAW, draw_times: 1 });
    }
    setData(void 0);
  };

  const onAfterFinishAnimation = (showValues: MaterialType[]) => {
    playDrawAudio();
    showRewardRef.current?.show(showValues, onClose);
    setShowCatchBall(-1);
    hasBallRef.current = false;
  };

  const { runAsync: runLotteryDraw } = useRequest(
    async (code?: string) => {
      try {
        const result = await lotteryDraw({
          ...currentDrawType,
          coupon_code: code,
        });
        if (code) {
          ticketRefresh();
        }
        const formatValues = formatMaterial(result);
        if (isAnimationFinishedRef.current) {
          onAfterFinishAnimation(formatValues);
        } else {
          setData(formatValues);
        }
        run();
      } catch (error: any) {
        toastRef.current?.show('error', error.message);
      } finally {
        setIsLoading(false);
      }
    },
    {
      manual: true,
    }
  );

  const handleAnimation = () => {
    isAnimationFinishedRef.current = false;
    if (animationRef.current) {
      animationRef.current?.animation?.play();
    }
  };

  const showTip = (value: DrawButtonType) => {
    const { draw_type, draw_times } = value;
    if (draw_type === DrawType.STAR_10) {
      rewardTipRef.current?.show(VisibleType.increase, 1);
      return;
    }
    if (draw_type === DrawType.STAR_100) {
      rewardTipRef.current?.show(VisibleType.increase, 10);
      return;
    }
    if (draw_type === DrawType.STAR_1500) {
      rewardTipRef.current?.show(VisibleType.max, draw_times);
      return;
    }
    if (draw_type === DrawType.FREE_DRAW) {
      const key = `lastExecuted_${draw_type}`;
      const lastExecuted = store.get(key);
      const now = dayjs().unix();
      const todayStart = dayjs().startOf('day').unix();
      const lastExecutedTime = lastExecuted ? parseInt(lastExecuted) : 0;
      if (lastExecutedTime < todayStart) {
        toastRef.current?.show(
          'none',
          'Daily reset for zoo consumption in basic draws.'
        );
        store.set(key, now.toString());
        return;
      }
    }
  };

  const handleDraw = async () => {
    try {
      const result = await onBefore(currentDrawType);
      const isPass = typeof result === 'boolean' ? result : result.isPass;
      const code = typeof result === 'boolean' ? void 0 : result.code;
      if (isPass) {
        handleAnimation();
        await runLotteryDraw(code);
      } else {
        debounceRef.current = false;
      }
    } catch (error) {
      debounceRef.current = false;
    }
  };

  const onFinish = useCallback(() => {
    isAnimationFinishedRef.current = true;
    setStartPhysics(false);
    if (data) {
      onAfterFinishAnimation(data);
    } else {
      setIsLoading(true);
    }
  }, [data]);

  useEffect(() => {
    if (animationRef.current && animationRef.current.animation) {
      animationRef.current?.animation?.addEventListener('finish', onFinish);
    }
    return () => {
      animationRef.current?.animation?.removeEventListener('finish', onFinish);
    };
  }, [animationRef.current, onFinish]);

  useEffect(() => {
    if (animationRef.current && animationRef.current.animation) {
      const animation = animationRef.current.animation;
      const frameCallback = () => {
        if (animation.currentTime && !isAnimationFinishedRef.current) {
          const currentOffset = animationRef.current!.getCurrentOffset();
          if (currentOffset === null) return;
          if (currentOffset >= 0.5) {
            setStartPhysics(true);
          }
          if (currentOffset >= 0.7 && !hasBallRef.current) {
            const randomBall = Math.floor(Math.random() * 6);
            setShowCatchBall(randomBall);
            hasBallRef.current = true;
          }
        }
        handleRef.current = requestAnimationFrame(frameCallback);
      };

      frameCallback();

      return () => {
        if (handleRef.current !== null) {
          cancelAnimationFrame(handleRef.current);
        }
      };
    }
  }, [animationRef.current]);

  const handleClick = () => {
    if (currentDrawType.draw_type === DrawType.COUPON_DRAW) {
      toastRef.current?.show(
        'none',
        'Prioritize using ticket consumption times'
      );
    }
    if (currentDrawType.draw_type !== DrawType.FREE_DRAW && isH5) return;
    if (debounceRef.current) return;
    debounceRef.current = true;

    playSpinAudio();
    handleDraw();
  };

  const handleFreeDraw = () => {
    inviteModal.current?.show();
  };

  const handleSwitch = (draw_type: DrawType) => {
    playBetAudio();
    if (
      currentDrawType.draw_type === DrawType.STAR_1500 &&
      draw_type === DrawType.STAR_1500
    ) {
      if (currentDrawType.draw_times === 1) {
        showTip({ draw_type, draw_times: 100 });
        setCurrentDrawType({ draw_type, draw_times: 10 });
      } else {
        showTip({ draw_type, draw_times: 10 });
        setCurrentDrawType({ draw_type, draw_times: 1 });
      }
      return;
    }
    if (draw_type !== DrawType.FREE_DRAW) {
      showTip({ draw_type, draw_times: 10 });
    }
    setCurrentDrawType({ draw_type, draw_times: 1 });
  };

  return (
    <div className="w-full min-h-screen bg-[url('@/assets/images/draw/catch/bg.png')] bg-no-repeat bg-cover bg-top">
      <HookContainer
        currentDrawType={currentDrawType}
        currentAmountZoo={currentAmountZoo}
        showCatchBall={showCatchBall}
      />
      <BallContainer startPhysics={startPhysics} />
      <RewardTip ref={rewardTipRef} />
      <div className="w-[375px] min-h-screen left-0 top-0 absolute pointer-events-none [background:linear-gradient(180deg,rgba(0,0,0,0.12)_0%,rgba(0,0,0,0.00)_100%)]"></div>
      <DrawBottom
        currentAmountZoo={currentAmountZoo}
        currentTickets={currentTickets}
        currentDrawType={currentDrawType}
        onDraw={handleClick}
        getFreeDraw={handleFreeDraw}
        onSwitch={handleSwitch}
      />
    </div>
  );
};

export default memo(DrawContainer);
