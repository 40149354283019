import { useMount, useUnmount } from 'ahooks';
import React, { useRef, useState } from 'react';
import clsx from 'clsx';

type Props = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    maskClassName?: string;
  }
>;
const RippleButton: React.FC<Props> = ({
  children,
  className,
  maskClassName,
  onClick,
  ...rest
}) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const clickRef = useRef<boolean>(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const animationEnd = () => {
    clickRef.current = false;
    setShowAnimation(false);
  };

  const calculateRipple = (
    target: EventTarget & HTMLDivElement,
    clientX: number,
    clientY: number
  ) => {
    const rect = target.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = clientX - rect.left - size / 2;
    const y = clientY - rect.top - size / 2;
    if (spanRef.current) {
      spanRef.current.style.width = spanRef.current.style.height = `${size}px`;
      spanRef.current.style.left = `${x}px`;
      spanRef.current.style.top = `${y}px`;
      setShowAnimation(true);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (clickRef.current) return;
    clickRef.current = true;
    calculateRipple(e.currentTarget, e.clientX, e.clientY);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e);
  };

  useMount(() => {
    if (spanRef.current) {
      spanRef.current.addEventListener('animationend', animationEnd);
      spanRef.current.addEventListener('animationcancel', animationEnd);
    }
  });

  useUnmount(() => {
    if (spanRef.current) {
      spanRef.current.removeEventListener('animationend', animationEnd);
      spanRef.current.removeEventListener('animationcancel', animationEnd);
    }
  });

  return (
    <div
      className={clsx(
        'relative overflow-hidden cursor-pointer',
        className ? className : 'w-fit h-fit'
      )}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      {...rest}
    >
      {children}
      <span
        ref={spanRef}
        className={clsx(
          'absolute rounded-full scale-0',
          maskClassName ? maskClassName : 'bg-[rgba(0,0,0,0.5)]',
          showAnimation && 'animate-ripple'
        )}
      ></span>
    </div>
  );
};

export default React.memo(RippleButton);
