/*
 * Created Date: December 9th 2024, 11:22:17 am
 * Author: zhoupengcheng
 * -----
 * Last Modified: December 10th 2024, 11:24:09 am
 */
import { isTMA as _isTMA } from '@telegram-apps/sdk-react';

export const isTMA = async () => {
  if (import.meta.env.VITE_APP_ENV == 'H5') {
    return false;
  }

  return await _isTMA();
};
