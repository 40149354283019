import { ADProvider, ISpinReport } from '@/api/game';
import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import { useAdsgram } from '@/hooks/useAdsgram';
import { useAdStar } from '@/hooks/useAdStar';
import { useRequest } from 'ahooks';
// import useTonAiAd from '@/hooks/useTonAiAd';
import { nanoid } from 'nanoid';
import React, { FC, useCallback } from 'react';
// import { TonAdProps } from 'ton-ai-sdk';

type Props = {
  watchAdModalRef: React.MutableRefObject<ModalRef | undefined>;
  onAdComplete: (
    provider: ADProvider,
    ad: Omit<ISpinReport, 'ad_provider'>
  ) => void;
  onAdError: () => void;
  onAdClose?: () => void;
  onOpenAd: () => void;
  currentAd?: ADProvider;
};
const WatchAdModal: FC<Props> = ({
  watchAdModalRef,
  onAdComplete,
  onAdError,
  onAdClose,
  onOpenAd,
  currentAd,
}) => {
  // const handleTonAiComplete = (ad?: TonAdProps) => {
  //   if (ad) {
  //     const adParams = {
  //       ad_block_id: ad.adId,
  //       campaign_id: ad.campaignId,
  //     };
  //     onAdComplete(ADProvider.TON_AI, adParams);
  //   }
  // };

  const onReward = useCallback((id: string) => {
    const adParams = {
      ad_block_id: id,
      campaign_id: id,
    };
    onAdComplete(ADProvider.AD_SGRAM, adParams);
  }, []);

  const onError = useCallback(() => {
    onAdError();
  }, []);

  const showAd = useAdsgram({
    onReward,
    onError,
  });

  const { run: runShowAd } = useRequest(
    async () => {
      const id = nanoid(5);

      await showAd(id);
    },
    { manual: true }
  );
  const handleAdStarComplete = () => {
    const id = nanoid(5);
    const adParams = {
      ad_block_id: id,
      campaign_id: id,
    };
    onAdComplete(ADProvider.AD_STAR, adParams);
  };

  const handleStarError = () => {
    if (currentAd === ADProvider.AD_STAR) {
      runShowAd();
    } else {
      onAdError();
    }
  };

  const getAdStar = useAdStar({
    onReward: handleAdStarComplete,
    onError: handleStarError,
    onClose: onAdClose,
  });

  // const handleError = () => {
  //   if (currentAd === ADProvider.TON_AI) {
  //     getAdStar();
  //   } else {
  //     onAdError();
  //   }
  // };

  // const getTonAd = useTonAiAd({
  //   onAdComplete: handleTonAiComplete,
  //   onAdError: handleError,
  //   onAdClose,
  // });

  return (
    <Modal
      ref={watchAdModalRef}
      icon={images.task.modalIcon1}
      onClose={() => {
        onOpenAd();
        watchAdModalRef.current?.hide();
      }}
    >
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-xl font-changa-one text-black">Get Free Spin</h3>
        <p className="font-poppins text-slate-400 w-[264px] mx-auto">
          Hamburgers are not enough, get the number of times by watching
          advertisements
        </p>
        <p className="font-poppins text-slate-400 w-[264px] mx-auto">
          Tips: Up to 10 times per day can be obtained
        </p>
        <div className="flex items-center justify-center w-[141px] h-[54px] my-[10px] mx-auto bg-[#F2F0FA] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px] [box-shadow:inset_0px_3px_0px_0px_rgba(0,0,0,0.03)]">
          <img
            src={images.game.spin.energyIcon}
            alt=""
            className="w-[26px] h-[26px]"
          />
          <span className="text-[24px] font-changa-one text-black">+5</span>
        </div>
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-bold font-changa-one"
          onClick={() => {
            onOpenAd();
            if (currentAd === ADProvider.AD_SGRAM) {
              runShowAd();
            } else {
              getAdStar();
            }
            watchAdModalRef.current?.hide();
          }}
        >
          Watch Ads Now
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(WatchAdModal);
