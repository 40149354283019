import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React, { FC } from 'react';

type Props = {
  broadcastingRef: React.MutableRefObject<ModalRef | undefined>;
  rewardAmount?: number;
};
const Broadcasting: FC<Props> = ({ broadcastingRef, rewardAmount }) => {
  return (
    <Modal
      ref={broadcastingRef}
      iconChildren={
        <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[262px]">
          <img
            className="w-full"
            src={images.mine.mineClaimSuccessIcon}
            width={100}
            height={100}
          />

          <img
            src={images.draw.ticketIcon}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120px]"
          />
        </div>
      }
    >
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(255,62,1,0.16)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] font-changa-one text-black">Ticket Gift</h3>
        <p className="font-poppins text-[14px] text-black opacity-30 w-[264px] mx-auto">
          Congratulations, The ticket obtained from Carnival can now be drawn in
          Tabizoo, and it must be used up within the validity period（7-day）
        </p>
        <div className="flex gap-[4px] items-center justify-center w-[141px] h-[54px] my-[10px] mx-auto bg-[rgba(0,0,0,0.04)] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px] [box-shadow:inset_0px_3px_0px_0px_rgba(0,0,0,0.03)]">
          <img
            src={images.draw.ticketInfo}
            alt=""
            className="w-[36px] h-[36px]"
          />
          <span className="text-[24px] font-changa-one text-[#F75F21]">
            +{rewardAmount || 0}
          </span>
        </div>
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-bold font-changa-one"
          onClick={() => {
            broadcastingRef.current?.hide();
          }}
        >
          Got It
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(Broadcasting);
