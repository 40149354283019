import { IDrawResult } from "@/api/draw";

export const mininodeIcons = [
  'wood',
  'coal',
  'oil',
  'electric',
  'nuclear',
  'antimatter',
];

export const mininodes = [
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
];
export enum MininodeType {
  BASIC = 'basic',
  ADVANCED = 'advanced',
  PARTY = 'party',
  STAR = 'star',
}
export type MaterialType = {
  type: string,
  name: string,
  url: string,
  partyIcon?: string;
  amount?: number,
  partnerName?: string,
  partyId?: number,
  botLink?: string
  energy?: number,
  advancedId?: number,
  available?: number,
  stake?: number
}

export const getDynamicMaterialUrls = (isReward?: boolean) => {
  let result: MaterialType[] = [];
  for (let i = 0; i < mininodeIcons.length; i++) {
    const url = new URL(
      `../assets/images/mininode/${mininodeIcons[i]}.png`,
      import.meta.url
    );
    result.push({ type: MininodeType.BASIC, name: mininodeIcons[i], url: url.pathname });
  }
  const starUrl = new URL(
    `../assets/images/mininode/star.png`,
    import.meta.url
  );
  if (isReward) {
    result.push({ type: MininodeType.STAR, name: 'star', url: starUrl.pathname });
  } else {
    result.unshift({ type: MininodeType.STAR, name: 'star', url: starUrl.pathname })
  }
  return result;
};

export const getDynamicBallUrls = () => {
  let result: string[] = [];
  for (let i = 0; i < 6; i++) {
    const url = new URL(
      `../assets/images/draw/catch/ball${i + 1}.png`,
      import.meta.url
    );
    result.push(url.pathname);
  }
  return result
}

export const getDynamicMiniNodeUrls = () => {
  let result: MaterialType[] = [];
  for (let i = 0; i < mininodes.length; i++) {
    const url = new URL(
      `../assets/images/mininode/${mininodes[i]}.png`,
      import.meta.url
    );
    result.push({ type: MininodeType.ADVANCED, advancedId: i + 1, name: mininodes[i], url: url.pathname });
  }
  return result;
};

export const formatMaterial = (values: IDrawResult) => {
  const { basic_material, party_token, star, advanced_material } = values;
  const basicMaterials = getDynamicMaterialUrls();
  const advancedMaterials = getDynamicMiniNodeUrls();
  const result: MaterialType[] = [];
  if (advanced_material) {
    result.push(...advanced_material.map(item => ({ type: MininodeType.ADVANCED, name: advancedMaterials[item.material_id - 1].name + ' mininode', url: advancedMaterials[item.material_id - 1].url, amount: item.quantity })));
  }
  if (basic_material) {
    result.push(...basic_material.map(item => ({ type: MininodeType.BASIC, name: basicMaterials[item.material_id].name, url: basicMaterials[item.material_id].url, amount: item.quantity })));
  }
  if (party_token) {
    result.push(...party_token.map(item => ({ type: MininodeType.PARTY, name: item.token_name, url: item.icon_url, amount: item.quantity, partnerName: item.partner_name, partyId: item.party_id, botLink: item.bot_link })));
  }
  if (star) {
    result.push(...star.map(item => ({ type: MininodeType.STAR, name: basicMaterials[0].name, url: basicMaterials[0].url, amount: item.quantity })));
  }
  const uniqueResult = result.reduce((acc, current) => {
    const x = acc.find(item => item.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc.map(item => item.name === current.name ? { ...item, amount: (item.amount ?? 0) + (current.amount ?? 0) } : item);
    }
  }, [] as MaterialType[]);

  return uniqueResult

}
