import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import React, { FC } from 'react';

type Props = {
  broadcastingRef: React.MutableRefObject<ModalRef | undefined>;
  rewardAmount?: number;
  onClose?: () => void;
};
const Broadcasting: FC<Props> = ({
  broadcastingRef,
  rewardAmount,
  onClose,
}) => {
  return (
    <Modal
      ref={broadcastingRef}
      iconChildren={
        <div className="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[262px]">
          <img
            className="w-full"
            src={images.mine.mineClaimSuccessIcon}
            width={100}
            height={100}
          />

          <img
            src={images.mine.mineClaimIcon}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[120px]"
          />
        </div>
      }
      onClose={onClose}
    >
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(255,62,1,0.16)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] font-changa-one text-black">
          Reward broadcasting
        </h3>
        <p className="font-poppins text-[14px] font-normal leading-[16px] opacity-30 w-[264px] mx-auto">
          You're so lucky, the reward hit you and you got an extra $Zoo. Go draw
          machine now!
        </p>
        <div className="flex items-center justify-center w-[141px] h-[54px] mt-[10px] mx-auto [background:rgba(0,0,0,0.04)] shadow-[0px_3px_0px_0px_rgba(0,0,0,0.03)_inset] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px]">
          <img
            src={images.header.zooIcon}
            alt=""
            className="w-[24px] h-[24px]"
          />
          <span className="text-[24px] font-changa-one text-[#F75F21]">
            +{rewardAmount || 100}
          </span>
        </div>
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[24px] text-nowrap font-bold font-changa-one"
          onClick={onClose}
        >
          Claim
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(Broadcasting);
