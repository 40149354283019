import { forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import images from '@/const/images';
import clsx from 'clsx';
import { VisibleType } from '../type';

export type RewardTipRef = {
  show: (value: VisibleType, drawTimes: number) => void;
};

const RewardTip = forwardRef((_, ref) => {
  const [visible, setVisible] = useState<VisibleType | undefined>(void 0);
  const [drawTimes, setDrawTimes] = useState<number>(1);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useImperativeHandle(ref, () => {
    const show = (value: VisibleType, drawTimes: number) => {
      setVisible(value);
      setDrawTimes(drawTimes);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setVisible(void 0);
        setDrawTimes(1);
      }, 2000);
    };

    return { show };
  }, []);
  return (
    <div
      className={clsx(
        'absolute z-50 left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2',
        visible !== undefined ? 'visible' : '[visibility:hidden]'
      )}
    >
      <div className="relative z-10">
        <img
          src={images.draw.starCoin}
          className="w-[24px] h-[24px] rotate-[15deg] absolute -top-[16px] left-[26px]"
        />
        {visible === VisibleType.increase ? (
          <img
            src={images.draw.catch.rewardIncrease}
            className="w-[50px] h-[36px] absolute -top-[35px] left-1/2 -translate-x-1/2"
          />
        ) : (
          <img
            src={images.draw.catch.rewardMax}
            className="w-[64px] h-[52px] absolute -top-[51px] left-[40px]"
          />
        )}
        <img
          src={images.draw.catch.rewardStar}
          className="w-[14px] h-[14px] absolute -top-[28px] right-[39.5px]"
        />
        <img
          src={images.draw.catch.rewardGift}
          className="w-[20px] h-[20px] absolute -z-10 -top-[12px] right-[37px]"
        />
        <div
          className={clsx(
            ' px-[12px] pt-[8px] gap-[4px] pb-[10px] flex flex-col items-center justify-center shadow-[0px_1px_2px_0px_rgba(255,255,255,0.08)_inset,0px_-3px_5px_0px_rgba(255,92,1,0.11)_inset] rounded-[8px]',
            visible === VisibleType.increase
              ? '[background:linear-gradient(180deg,#FFCB43_0%,#FFA225_100%)]'
              : '[background:linear-gradient(180deg,#FF7138_0%,#FF6526_100%)]'
          )}
        >
          <span className="text-white text-center font-changa-one text-[14px] font-normal leading-[16px] capitalize">
            {visible === VisibleType.increase
              ? 'Reward increase'
              : 'Reward max'}{' '}
            ×{drawTimes}
          </span>
          {visible === VisibleType.max && (
            <span className="text-white text-center font-poppins text-[10px] font-normal leading-[12px] capitalize opacity-70">
              obtain advanced materials
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

export default memo(RewardTip);
