import React from 'react';
import ImageWithDefault from '@/components/ImageWithDefault';
import images from '@/const/images';
import RewardInfo from './RewardInfo';
import { MaterialType } from '@/utils/mininode';
import { useNavigate } from 'react-router-dom';
import { postEvent } from '@telegram-apps/sdk-react';
import useIsH5 from '@/hooks/useIsH5';
import { openLinkInNewTab } from '@/utils';

const RewardsContainer = ({
  list,
  hasLight,
  hasPartyToken,
}: {
  list: MaterialType[];
  hasLight: boolean;
  hasPartyToken: MaterialType | undefined;
}) => {
  const navigate = useNavigate();
  const isH5 = useIsH5();
  const goTabiParty = () => {
    navigate(`/tabiParty/${hasPartyToken?.partyId}`);
  };

  const goPartyBot = () => {
    if (hasPartyToken?.botLink?.includes('https://t.me') && !isH5) {
      const link_url =
        hasPartyToken?.botLink?.replace('https://t.me', '') || '';
      postEvent('web_app_open_tg_link', {
        path_full: link_url,
      });
    } else {
      if (isH5) {
        openLinkInNewTab(hasPartyToken?.botLink, '_blank');
      } else {
        postEvent('web_app_open_link', {
          url: hasPartyToken?.botLink || '',
        });
      }
    }
  };

  return (
    <>
      {hasPartyToken && list.length < 3 && (
        <div className="flex flex-col items-center gap-[8px] w-[291px]">
          <span className="self-stretch text-white  font-poppins text-[10px] font-normal leading-[14px]">
            Rewards from
          </span>
          <div
            onClick={goPartyBot}
            className="flex items-center cursor-pointer justify-between self-stretch [background:rgba(255,255,255,0.10)] px-[12px] py-[8px] rounded-[16px]"
          >
            <div className="flex items-center gap-[4px]">
              <ImageWithDefault
                src={hasPartyToken.url}
                alt=""
                className="w-[16px] h-[16px] rounded-full"
              />
              <span className="self-stretch text-white font-changa-one text-[12px] font-normal leading-[14px]">
                {hasPartyToken.partnerName}
              </span>
            </div>
            <img
              src={images.guide.joinCommunity.communityLinkIcon}
              alt=""
              className="w-[16px] h-[16px] rounded-full"
            />
          </div>
          <div
            onClick={goTabiParty}
            className="flex flex-col cursor-pointer items-start gap-[8px] self-stretch [background:linear-gradient(180deg,rgba(255,255,255,0.10)_0%,rgba(255,255,255,0.00)_100%)] p-[12px] rounded-[8px]"
          >
            <span className="w-[261px] text-white font-poppins text-[10px] font-normal leading-[14px] pb-[8px] border-b border-b-solid border-b-[rgba(255,255,255,0.05)]">
              You must complete the corresponding tasks at Tabiparty before you
              can claim your reward.
            </span>
            <div className="flex items-center justify-between w-full">
              <span className="self-stretch text-white font-changa-one text-[12px] font-normal leading-[14px]">
                Go and finish the task
              </span>
              <img
                src={images.guide.joinCommunity.communityLinkIcon}
                alt=""
                className="w-[16px] h-[16px] rounded-full"
              />
            </div>
          </div>
        </div>
      )}
      <RewardInfo list={list} hasLight={hasLight} />
    </>
  );
};

export default React.memo(RewardsContainer);
