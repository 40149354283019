import { useNavigate, useLocation } from 'react-router-dom';
import images from '@/const/images';
import { transferNumber } from '@/utils';
import useProfile from '@/hooks/useProfile';
import { ModalRef } from '../Modal';
import { animated, useSpring } from '@react-spring/web';
import { useMemo } from 'react';
import clsx from 'clsx';

const Header = ({
  volumeModal,
  crystal = false,
  isDraw = false,
}: {
  volumeModal: React.MutableRefObject<ModalRef | undefined>;
  crystal?: boolean;
  isDraw?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userProfile } = useProfile();

  const goProfile = () => {
    navigate('/profile', { state: location.pathname });
  };

  const { zoo, crystalCoins, coins, star } = useMemo(() => {
    return {
      zoo: transferNumber(userProfile?.user?.zoo_coins || 0),
      crystalCoins: transferNumber(userProfile?.user?.crystal_coins || 0),
      coins: transferNumber(userProfile?.user?.coins || 0),
      star: transferNumber(userProfile?.user?.star || 0),
    };
  }, [userProfile?.user]);

  const currentValue = useSpring({
    zooValue: zoo[0],
    crystalCoinsValue: crystalCoins[0],
    coinsValue: coins[0],
    starValue: star[0],
  });

  return (
    <div className="absolute top-[14px] w-full flex items-center justify-center z-[9999]">
      <div className="flex justify-between items-center w-[375px] px-[10px]">
        <div
          className="w-[44px] relative h-[44px] bg-white cursor-pointer rounded-[50%] flex justify-center items-center shadow-[0px_-4px_0px_0px_#D6C6DB_inset]"
          onClick={goProfile}
        >
          <img
            className="w-[40px] h-[40px] rounded-[50%]"
            src={
              userProfile?.user.photo_url
                ? userProfile?.user.photo_url
                : userProfile?.user.platform_user_info?.user?.avatar_url
                  ? userProfile?.user.platform_user_info?.user?.avatar_url
                  : images.game.avatarDefault
            }
          />
          <img
            className="absolute w-[37px] bottom-[-3px] left-[50%] -translate-x-1/2"
            src={images.header.assetsIcon}
            alt=""
          />
        </div>

        <div className="flex gap-[20px] mt-[3px]">
          <div
            className={clsx(
              'h-[28px] bg-white cursor-pointer shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-lg shrink-0 flex items-center pl-[20px] pr-[10px] relative',
              crystal ? 'max-w-[70px]' : 'max-w-[98px]'
            )}
            onClick={goProfile}
          >
            <img
              className="w-[30px] h-[30px] absolute left-[-15px] top-[-3px]"
              src={images.header.zooIcon}
            />
            <div className="text-[#272C2A] text-[12px] font-changa-one mt-[-3px] truncate">
              <animated.span>
                {currentValue.zooValue.to((n) => Number(n).toFixed(2))}
              </animated.span>
              {zoo[1]}
            </div>
          </div>

          {!isDraw && (
            <div
              className={clsx(
                'h-[28px] bg-white cursor-pointer shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-lg shrink-0 flex items-center pl-[20px] pr-[10px] relative',
                crystal ? 'max-w-[70px]' : 'max-w-[98px]'
              )}
              onClick={goProfile}
            >
              <img
                className="w-[30px] h-[30px] absolute left-[-15px] top-[-3px]"
                src={images.header.coinIcon}
              />
              <div className="text-[#272C2A] text-[12px] font-changa-one mt-[-3px] truncate">
                <animated.span>
                  {currentValue.coinsValue.to((n) => Number(n).toFixed(2))}
                </animated.span>
                {coins[1]}
              </div>
            </div>
          )}

          {crystal && (
            <div
              className="h-[28px] max-w-[70px] cursor-pointer bg-white shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-lg shrink-0 flex items-center pl-[20px] pr-[10px] relative"
              onClick={goProfile}
            >
              <img
                className="w-[30px] h-[30px] absolute left-[-15px] top-[-3px]"
                src={images.header.crystalIcon}
              />
              <div className="text-[#272C2A] text-[12px] font-changa-one mt-[-3px] truncate">
                <animated.span>
                  {currentValue.crystalCoinsValue.to((n) =>
                    Number(n).toFixed(2)
                  )}
                </animated.span>
                {crystalCoins[1]}
              </div>
            </div>
          )}

          {isDraw && (
            <div
              className="h-[28px] max-w-[98px] bg-white cursor-pointer shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] rounded-lg shrink-0 flex items-center pl-[20px] pr-[10px] relative"
              onClick={goProfile}
            >
              <img
                className="w-[30px] h-[30px] absolute left-[-15px] top-[-3px]"
                src={images.header.starIcon}
              />
              <div className="text-[#272C2A] text-[12px] font-changa-one mt-[-3px] truncate">
                <animated.span>
                  {currentValue.starValue.to((n) => Number(n).toFixed(2))}
                </animated.span>
                {star[1]}
              </div>
            </div>
          )}
          <div
            className="h-[28px] cursor-pointer ml-[-12px] bg-white shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[6px] pb-[2px] shrink-0  rounded-lg flex items-center justify-center"
            onClick={() => {
              volumeModal.current?.show();
            }}
          >
            <img
              className="w-[16px] h-[16px]"
              src={images.common.volumeControl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
