import { getOwnedRewards } from "@/api/draw";
import { getMiniNodeRangePoints } from "@/api/mininode";
import { IRouterRootLoaderData } from "@/router";
import { getDynamicMaterialUrls, getDynamicMiniNodeUrls, MaterialType, MininodeType } from "@/utils/mininode";
import { useMount, useRequest } from "ahooks";
import { useMemo } from "react";
import { useAsyncValue } from "react-router-dom";

export type OwnedRewardsType = {
  advancedMaterials: Array<MaterialType>;
  basicMaterials: Array<MaterialType>;
};
const useOwnedRewards = (star = 0) => {
  const loaderData = useAsyncValue() as IRouterRootLoaderData;

  const { data, refresh } = useRequest(getOwnedRewards, {
    manual: true,
    cacheKey: 'ownedRewards',
  });

  const { data: rangePointsData, run: runRange } = useRequest(getMiniNodeRangePoints, {
    manual: true,
    cacheKey: 'getMiniNodeRangePoints',
  });


  useMount(() => {
    if (!loaderData.rangePoints) {
      runRange()
    }
    refresh();
  });


  const rangePointsValues = useMemo(() => {
    if (rangePointsData) return rangePointsData;

    return loaderData?.rangePoints;
  }, [loaderData?.rangePoints, rangePointsData]);

  const values = useMemo(() => {
    if (data) return data;

    return loaderData?.ownedRewards;
  }, [loaderData?.ownedRewards, data]);


  const ownedRewards: OwnedRewardsType | undefined = useMemo(() => {
    if (!values) return;
    const { owned_basic_materials, owned_advanced_materials, owned_party_tokens, owned_staked_advanced_materials } = values;
    const basic = getDynamicMaterialUrls();
    const advanced = getDynamicMiniNodeUrls()
    let basicMaterials: MaterialType[] = basic;
    let advancedMaterials: MaterialType[] = advanced.map(item => ({ ...item, energy: rangePointsValues?.find(value => value.advanced_material_id === item.advancedId)?.range_points_amount || 0 }));
    if (owned_basic_materials) {
      basicMaterials = basicMaterials.map((item, index) => {
        const ownedItem = owned_basic_materials.find(basicItem => basicItem.material_id === index);
        if (item.type === MininodeType.STAR) {
          return { ...item, amount: star }
        }

        return { ...item, amount: ownedItem?.quantity ?? 0 };
      });
    }
    if (owned_party_tokens) {
      basicMaterials.push(...owned_party_tokens.map(item => ({ type: MininodeType.PARTY, partyIcon: item.party_icon_url, name: item.token_name, url: item.icon_url, amount: item.amount })));
    }
    if (owned_advanced_materials) {
      advancedMaterials = advancedMaterials.map((item) => {
        const available = owned_advanced_materials.find(basicItem => basicItem.material_id === item.advancedId)?.quantity ?? 0;
        let stake = 0;
        if (owned_staked_advanced_materials) {
          stake = owned_staked_advanced_materials.find(basicItem => basicItem.advanced_material_id === item.advancedId)?.quantity ?? 0;
        }
        return { ...item, amount: available + stake, available, stake };
      })
    }

    return { basicMaterials, advancedMaterials };
  }, [values, star]);

  return {
    ownedRewards,
    refresh
  }
}


export default useOwnedRewards;
