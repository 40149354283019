export const transferSeconds = (seconds: number): string => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondss = seconds % 60;
  return `${minutes < 10 ? '0' + minutes : minutes}:${secondss < 10 ? '0' + secondss : secondss}`;
};

export type DaysType = {
  days: number;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
  isOver?: boolean;
};
export const transferDays = (seconds: number): DaysType => {
  if (seconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0, isOver: true };
  }

  const days = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondss = seconds % 60;

  return {
    days,
    hours: hours < 10 ? '0' + hours : hours,
    minutes: minutes < 10 ? '0' + minutes : minutes,
    seconds: secondss < 10 ? '0' + secondss : secondss,
  };
};

const truncNumber = (num: number) => {
  const truncated = Math.trunc(num * 100) / 100;
  return truncated % 1 === 0 ? truncated.toString() : truncated.toFixed(3);
}

export const transferNumber = (num: number) => {
  if (num >= 1000000000) {
    return [truncNumber(num / 1000000000), 'B'] as const;
  } else if (num >= 1000000) {
    return [truncNumber(num / 1000000), 'M'] as const;
  } else if (num >= 1000) {
    return [truncNumber(num / 1000), 'K'] as const;
  } else {
    return [num + '', ''] as const;
  }
};

const clamp = (value: number) => {
  const maxValue = Math.min(550, value)
  const minValue = Math.max(328, value)

  return value <= 550 ? Math.max(minValue, maxValue) : 550
}

export const getInnerWidthRatio = () => {
  return clamp(window.innerWidth) / 375;
};
export const pxToVw = (px: number) => {
  return `${(px / (window.innerWidth || 375)) * 100}vw`;
};

const superscriptMap: { [key: string]: string } = {
  '0': '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
  '.': '.'
};

const superscript = (decimalStr: string) => {
  let zeroCount = 0;
  let nonZeroIndex = 0;

  for (let i = 0; i < decimalStr.length; i++) {
    if (decimalStr[i] === '0') {
      zeroCount++;
    } else {
      nonZeroIndex = i;
      break;
    }
  }

  let formattedDecimalPart = '';

  if (zeroCount >= 3) {
    const zeroCountStr = (zeroCount - 1).toString();
    formattedDecimalPart += '0' + zeroCountStr.split('').map(char => superscriptMap[char]).join('');
    formattedDecimalPart += decimalStr.slice(nonZeroIndex, nonZeroIndex + 4);
  } else {
    formattedDecimalPart += decimalStr.slice(0, 5);
  }

  return formattedDecimalPart
};

export function formatCurrency(
  num: number | undefined,
  zero?: boolean,
  needIndex?: boolean
): string {
  if (num === undefined || num === 0) return !!zero ? '0' : '000,000';
  let [intStr, decimalStr] = Math.abs(num).toString().split('.');

  let formattedIntPart = '';
  while (intStr.length > 3) {
    formattedIntPart = ',' + intStr.slice(-3) + formattedIntPart;
    intStr = intStr.slice(0, -3);
  }
  formattedIntPart = intStr + formattedIntPart;
  // 如果有小数部分，则拼接回去
  if (decimalStr) {
    if (needIndex) {
      const str = superscript(decimalStr)
      formattedIntPart += '.' + str;
    } else {
      formattedIntPart += '.' + decimalStr.slice(0, 4);
    }

  }

  return num > 0 ? formattedIntPart : '-' + formattedIntPart;
}

export function encodeBase58(num: number) {
  const base58Chars =
    '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

  if (num === 0) return base58Chars[0];
  let encoded = '';
  while (num > 0) {
    let remainder = num % 58;
    num = Math.floor(num / 58);
    encoded = base58Chars[remainder] + encoded;
  }
  return encoded;
}

export function isValidERC20Address(address: string) {
  const erc20AddressRegex = /^0x[a-fA-F0-9]{40}$/;
  return erc20AddressRegex.test(address);
}

export function inValidTONAddress(address: string) {
  const tonAddressRegex = /^(EQ|UQ)[0-9A-Za-z-_]{46}$/;
  return tonAddressRegex.test(address);
}

export function tTansfer(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h:${minutes}m`;
}

export function formatAddress(address?: string) {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-7)}`;
}

export function formatFloatNumber(num: number) {
  return Math.round(num * 100);
}
export const openLinkInNewTab = (url?: string, target = '_blank') => {
  const link = document.createElement('a');
  if (url) {
    link.href = url;
    link.target = target;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
