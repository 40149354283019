import { useRef, useImperativeHandle, forwardRef } from 'react';
import { useLocalStorageState, useRequest } from 'ahooks';
import { postEvent } from '@telegram-apps/sdk-react';
import Modal, { ModalRef } from '@/components/Modal';
import Toast, { ToastRef } from '@/components/Toast';
import images from '@/const/images';
import { getTaskRecord, TaskStatus, verifyTask } from '@/api/guide';
import Loading from '@/components/Loading';

export interface JoinCommunityModalRef {
  show: () => void;
  hide: () => void;
}

const JoinCommunityModal = forwardRef((_, ref) => {
  const joinCommunityModal = useRef<ModalRef>();
  const toastRef = useRef<ToastRef>();

  useImperativeHandle(ref, () => {
    const show = () => {
      taskRecordRun();
      joinCommunityModal?.current?.show();
    };

    const hide = () => {
      joinCommunityModal?.current?.hide();
    };

    return { show, hide };
  }, []);

  const [, setIsShowJoinCommunityModal] = useLocalStorageState<boolean>(
    'isShowJoinCommunityModal',
    {
      defaultValue: true,
    }
  );

  // tg验证相关逻辑
  const tgModalRef = useRef<ModalRef>();
  const [isOpenTgChannel, setIsOpenTgChannel] = useLocalStorageState<boolean>(
    'boot_join_tabi_channel',
    {
      defaultValue: false,
    }
  ); //是否打开TgChannel的Modal

  const { data: taskRecord, run: taskRecordRun } = useRequest(
    async () => {
      const res = await getTaskRecord();
      if (
        res?.find((task) => task.task_tag === 'boot_join_tabi_channel')
          ?.user_task_status === TaskStatus.Done
      ) {
        joinCommunityModal?.current?.hide();
        setIsShowJoinCommunityModal(false);
      }
      return res;
    },
    { manual: true }
  );

  const handleTgVerify = () => {
    if (
      taskRecord?.find((task) => task.task_tag === 'boot_join_tabi_channel')
        ?.user_task_status === TaskStatus.Done
    ) {
      openTGChannel();
    } else {
      tgModalRef.current?.show();
    }
  };

  const openTGChannel = (isCache?: boolean) => {
    if (isCache) {
      setIsOpenTgChannel(true);
    }
    const url = taskRecord?.find(
      (task) => task.task_tag === 'boot_join_tabi_channel'
    )?.link_url;
    if (url) {
      postEvent('web_app_open_tg_link', { path_full: url });
    } else {
      toastRef?.current?.show('error', 'Task Not Found!');
    }
  };

  //tg是否验证通过
  const { run: verifyTgChannelRun, loading: verifyTgLoading } = useRequest(
    async () => {
      if (!isOpenTgChannel) {
        toastRef?.current?.show('error', 'Task Not Completed');
        return;
      }

      const params = {
        task_tag: 'boot_join_tabi_channel',
      };
      const res = await verifyTask(params);
      tgModalRef.current?.hide();
      if (res?.verify) {
        toastRef?.current?.show('success', 'Task Completed!');
        taskRecordRun();
        setIsOpenTgChannel();
        joinCommunityModal?.current?.hide();
        setIsShowJoinCommunityModal(false);
      } else {
        toastRef?.current?.show('error', 'Task Not Completed!');
        setIsOpenTgChannel(false);
      }
      return res;
    },
    { manual: true }
  );

  return (
    <>
      <Modal ref={joinCommunityModal} icon={images.task.modalIcon} hideClose>
        <div className="w-[339px] pb-[35px] shrink-0 [background:linear-gradient(180deg,rgba(158,255,1,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[50px] text-center">
          <h3 className="text-[24px] font-changa-one text-black">
            Join our community
          </h3>
          <div>
            <div className="w-full flex justify-center flex-wrap gap-5 p-2.5">
              <div className="w-[147px] h-[72px] box-border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_rgba(0,0,0,0.15)_inset] relative p-2 rounded-lg bg-[#4d5055] text-left">
                <img
                  className="absolute right-2 top-3 w-[48px]"
                  width={48}
                  height={48}
                  src={images.guide.joinCommunity.xIcon}
                />
                <h4 className="text-xl text-white m-0 font-changa-one">
                  1100K+
                </h4>
                <p className="text-xs font-medium text-white w-[80%] p-0 font-poppins text-[10px] absolute">
                  twitter members
                </p>
              </div>
              <div className="w-[147px] h-[72px] box-border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_rgba(0,0,0,0.15)_inset] relative p-2 rounded-lg bg-[#3e4694] text-left">
                <img
                  className="absolute right-2 top-3 w-[48px]"
                  width={48}
                  height={48}
                  src={images.guide.joinCommunity.discordIcon}
                />
                <h4 className="text-xl text-white m-0 font-changa-one">
                  1120K+
                </h4>
                <p className="text-xs font-medium text-white w-[80%] p-0 font-poppins text-[10px] absolute">
                  discord members
                </p>
              </div>
              <div className="w-[147px] min-h-[110px] box-border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_rgba(0,0,0,0.15)_inset] relative p-2 pb-4 rounded-lg bg-[#76838d] text-left">
                <img
                  className="absolute right-2 top-3 w-[48px]"
                  width={48}
                  height={48}
                  src={images.guide.joinCommunity.addressIcon}
                />
                <h4 className="text-xl text-white m-0 font-changa-one">
                  5.60M+
                </h4>
                <p className="text-xs font-medium text-white w-[70%] p-0 font-poppins text-[10px] absolute">
                  Total unique addresses
                </p>
              </div>
              <div className="w-[147px] min-h-[110px] box-border shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_rgba(0,0,0,0.15)_inset] relative p-2 pb-4 rounded-lg bg-[#f75f21] text-left">
                <img
                  className="absolute right-2 top-3 w-[48px]"
                  width={48}
                  height={48}
                  src={images.guide.joinCommunity.redditIcon}
                />
                <h4 className="text-xl text-white m-0 font-changa-one">
                  TOP 1%
                </h4>
                <p className="text-xs font-medium text-white w-[70%] p-0 font-poppins text-[10px] absolute">
                  The sole crypto community to achieve Top1% size on Reddit
                </p>
              </div>
            </div>
            <div className="w-[310px] h-px opacity-10 mx-auto mt-[1vh] mb-[2vh] bg-black"></div>
            {taskRecord?.map((task) => (
              <div key={task?.id} className="w-[310px] mx-auto">
                <div
                  className="w-[310px] cursor-pointer h-16 shadow-[0px_1px_4px_0px_rgba(0,0,0,0.4),0px_-3px_0px_0px_#dcdcdc_inset] flex justify-between items-center mt-5 px-2.5 py-0 rounded-lg bg-white"
                  onClick={handleTgVerify}
                >
                  <div className="flex items-center font-changa-one text-black">
                    <img
                      className="w-[38px] mr-3"
                      width={38}
                      height={38}
                      src={images.guide.joinCommunity.communityTelegramIcon}
                    />
                    {task?.task_name}
                  </div>
                  <img
                    className="w-[30px] h-[30px]"
                    src={
                      taskRecord?.find(
                        (task) => task.task_tag === 'boot_join_tabi_channel'
                      )?.user_task_status === TaskStatus.Done
                        ? images.task.completedIcon
                        : images.guide.joinCommunity.communityLinkIcon
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* tg验证弹窗 */}
      <Modal ref={tgModalRef} icon={images.task.modalIcon}>
        <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(158,255,1,0.17)_0%,rgba(255,255,255,0.00)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
          <h3 className="text-xl font-changa-one text-black">
            Join The Telegram Channel
          </h3>
          {/* <p className="font-poppins text-[rgba(0,0,0,0.3)]">
            Join TABI Telegram Channel
          </p> */}
          <div
            className="flex cursor-pointer w-[291px] h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)] px-[135px] py-3.5 rounded-[47px] mx-auto text-white mt-[30px] font-bold font-changa-one"
            onClick={() => openTGChannel(true)}
          >
            Go
          </div>
          <div
            className="flex w-[291px] cursor-pointer h-[46px] justify-center items-center gap-2.5 shrink-0 [background:#fff] px-[135px] py-3.5 rounded-[47px] mx-auto mt-[15px] font-bold font-changa-one text-[#FD6019] border border-[#FD6019] loader-primary"
            onClick={verifyTgChannelRun}
          >
            {verifyTgLoading ? <Loading /> : 'Check'}
          </div>
        </div>
      </Modal>

      <Toast ref={toastRef} />
    </>
  );
});

export default JoinCommunityModal;
