import { ITickets } from '@/api/draw';
import Modal, { ModalRef } from '@/components/Modal';
import images from '@/const/images';
import { padZero } from '@/utils/number';
import { useCountDown } from 'ahooks';
import React, { FC, useMemo } from 'react';

type Props = {
  ticketModalRef: React.MutableRefObject<ModalRef | undefined>;
  tickets?: ITickets[];
};
const TicketModal: FC<Props> = ({ ticketModalRef, tickets }) => {
  const { len, currentTicket } = useMemo(() => {
    return {
      len: tickets?.length || 0,
      currentTicket: tickets?.[0],
    };
  }, [tickets]);
  const [_, formattedRes] = useCountDown({
    targetDate: (currentTicket?.expired_at || 0) * 1000,
  });
  return (
    <Modal ref={ticketModalRef} icon={images.task.modalIconPurple}>
      <div className="w-[339px] pb-[20px] shrink-0 [background:linear-gradient(180deg,rgba(37,1,255,0.17)_0%,rgba(255,255,255,0)_100%),#FFF] shadow-[0px_2px_0px_0px_rgba(255,255,255,0.99)_inset,0px_1px_12px_0px_rgba(0,0,0,0.40),0px_-5px_0px_0px_#DCDCDC_inset] rounded-[20px] pt-[60px] text-center">
        <h3 className="text-[20px] font-changa-one text-black">
          Carnival Ticket
        </h3>
        <p className="font-poppins text-[14px] text-black opacity-30 w-[260px] mx-auto">
          From Carnival
        </p>
        <div className="flex items-center justify-between px-[12px] w-[215px] h-[54px] my-[10px] mx-auto bg-[rgba(0,0,0,0.04)] border-[1.4px] border-solid border-[#EAE7F2] rounded-[10px] [box-shadow:inset_0px_3px_0px_0px_rgba(0,0,0,0.03)]">
          <div className="flex items-center gap-[4px]">
            <img
              src={images.draw.ticketInfo}
              alt=""
              className="w-[24px] h-[24px]"
            />
            <span className="text-[#333] font-changa-one text-[14px] font-normal leading-[14px] capitalize">
              ticket
            </span>
          </div>
          <span className="text-[#FD6019] font-changa-one text-[20px] font-normal leading-[14px] capitalize">
            {len}
          </span>
        </div>
        {len > 0 && (
          <>
            <span className="text-black font-poppins text-[12px] font-normal leading-[14px] opacity-50">
              Expiration date
            </span>
            <div className="w-[291px] flex items-center justify-center text-white font-changa-one text-[10px] font-normal leading-[16px] border-solid border-t border-t-[rgba(0,0,0,0.02)] mx-auto mt-[6px] pt-[6px]">
              <div className="h-[25px] mr-2 flex justify-center items-center [background:#FF6121] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-md">
                {formattedRes.days} Days
              </div>
              <div className="w-[25px] h-[25px] flex justify-center items-center [background:#FF6121] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-md">
                {padZero(formattedRes.hours)}
              </div>
              <div className="text-[#4D5055] font-changa-one text-sm font-normal leading-[14px] tracking-[0.84px] mx-1">
                :
              </div>
              <div className="w-[25px] h-[25px] flex justify-center items-center [background:#FF6121] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-md">
                {padZero(formattedRes.minutes)}
              </div>
              <div className="text-[#4D5055] font-changa-one text-sm font-normal leading-[14px] tracking-[0.84px] mx-1">
                :
              </div>
              <div className="w-[25px] h-[25px] flex justify-center items-center [background:#FF6121] shadow-[0px_-2px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.4px_1px_0px_rgba(0,0,0,0.34)] p-[5px] rounded-md">
                {padZero(formattedRes.seconds)}
              </div>
            </div>
          </>
        )}
        <div
          className="flex w-[291px] h-[46px] cursor-pointer justify-center items-center gap-2.5 shrink-0 [background:#FD6019] shadow-[0px_-2.8px_0px_1px_rgba(0,0,0,0.13)_inset,0px_0.8px_1px_0px_rgba(0,0,0,0.34)]  rounded-[47px] mx-auto text-white mt-[30px] text-nowrap font-bold font-changa-one"
          onClick={() => {
            ticketModalRef.current?.hide();
          }}
        >
          Got It
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TicketModal);
